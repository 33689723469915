export const parameter = {
    code: 'HBCDs',
    label : 'HBCDs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "HBCDs", label: "alpha-HBCD (134237-50-6)", value: "alpha-HBCD (134237-50-6)" },
{ group: "HBCDs", label: "beta-HBCD (134237-51-7)", value: "beta-HBCD (134237-51-7)" },
{ group: "HBCDs", label: "gamma-HBCD (134237-52-8)", value: "gamma-HBCD (134237-52-8)" },
{ group: "HBCDs", label: "delta-HBCD ()", value: "delta-HBCD ()" },

    ]
}
