import * as sectionCommon from './fields/sectionCommon'
import * as bisphenols from './fields/bisphenols.js'
import * as elements from './fields/elements.js'
import * as hbcds from './fields/hbcds.js'
import * as hcbd from './fields/hcbd.js'
import * as microcystins from './fields/microcystins.js'
import * as musks from './fields/musks.js'
import * as nfrs from './fields/nfrs.js'
import * as opfrs from './fields/opfrs.js'
import * as pahs from './fields/pahs.js'
import * as pahs_substituted from './fields/pahs_substituted.js'
import * as paraffins from './fields/paraffins.js'
import * as pbbs from './fields/pbbs.js'
import * as pbdes from './fields/pbdes.js'
import * as pcbs from './fields/pcbs.js'
import * as pcbs_dl from './fields/pcbs_dl.js'
import * as pcdds_fs from './fields/pcdds_fs.js'
import * as pcns from './fields/pcns.js'
import * as pesticides_basic from './fields/pesticides_basic.js'
import * as pesticides_cups from './fields/pesticides_cups.js'
import * as pesticides_drines from './fields/pesticides_drines.js'
import * as pesticides_others from './fields/pesticides_others.js'
import * as pesticides_pyrethroids from './fields/pesticides_pyrethroids.js'
import * as pfas from './fields/pfas.js'
import * as pharmaceuticals from './fields/pharmaceuticals.js'
import * as phthalates from './fields/phthalates.js'
import * as sweeteners from './fields/sweeteners.js'




export const contact_information = {
    code: 'contact_information',
    label: 'Your Contact Information',
    type: 'group',
    isValid: true,
    isDisabled: false,
    fields: [
        {
            code: 'fullName',
            label : 'Full name',
            dataType : 'string',
            widgetType: 'input',
            validations: {
                required: true
            }
        },
        {
            code: 'email',
            label : 'Email Address',
            dataType : 'string',
            widgetType: 'input',
            validations: {
                required: true,
                email: true
            }
        },
        {
            code: 'institution',
            label : 'Your Institution',
            dataType : 'string',
            widgetType: 'input',
        },
    ]
}

export const monitoring_network = {
    code: 'monitoring_network',
    label: 'Monitoring network',
    type: 'group',
    isValid: true,
    isDisabled: false,
    fields: [
        {
            code: 'monitoringNetworkTitle',
            label : 'Title of the monitoring network (project/study)',
            dataType : 'string',
            widgetType: 'input',
            validations: {
                required: true
            }
        },
        {
            code: 'monitoringNetworkAcronym',
            label : 'Acronym of the monitoring network (project/study)',
            dataType : 'string',
            widgetType: 'input',
        },
        {
            code: 'monitoringNetworkDataSourceType',
            label : 'Type of data source',
            dataType : 'enum',
            widgetType: 'autocomplete',
            chips: true,
            multiselect: false,
            options: [
                {value: 'monitoring', label:'Monitoring'},
                {value: 'case-study', label:'Case study'},
                {value: 'other', label:'Other'}
            ]
        },
        {
            code: 'monitoringNetworkDataSourceTypeExtended',
            label : 'Please specify your Data source',
            dataType : 'string',
            widgetType: 'input',
            isDisabled: false,
            isInvisible: true,
            skips: [
                {
                    fieldCode: 'monitoringNetworkDataSourceType',
                    value: 'other',
                    type: 'isInvisible'
                }
            ],
        },
        {
            code: 'monitoringNetworkDataOwner',
            label : 'Data owner - institution',
            dataType : 'string',
            widgetType: 'input',
        },
        
        {
            code: 'monitoringNetworkDataOwnerContactPoint',
            label : 'Point of contact - name',
            dataType : 'string',
            widgetType: 'input',
        },
        {
            code: 'monitoringNetworkDataOwnerContactPoint',
            label : 'Point of contact - email',
            dataType : 'string',
            widgetType: 'input',
            validations: {
                required: false,
                email: true
            }
        },
        {
            code: 'monitoringNetworkWebPage',
            label : 'Website adress',
            dataType : 'string',
            widgetType: 'input',
        },
    ]
}

export const database = {
    code: 'database',
    label: 'Database',
    type: 'repeating_group',
    isValid: true,
    isDisabled: false,
    fields: [
        {
            code: 'database_info',
            label : 'Which databases or data repositories are used to store monitoring network data?',
            dataType : 'heading',
        },
        {
            code: 'fullNameOfTheDatabase',
            label:'Full name of the database',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code: 'acronymForTheDatabase',
            label: 'Acronym for the database',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code:'onlineLink',
            label: 'Online link',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code:'dataDownloadLink',
            label: 'Data download link',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code:'dataAccessibility',
            label: 'Data accessibility',
            dataType: 'string',
            widgetType: 'input',
            hint: '(i.e. public access online/private access/on demand)'
        },
        {
            code:'formatDataExport',
            label: 'Format data export',
            dataType: 'string',
            widgetType: 'input',
            hint: '(i.e. Excel/ csv/ xml/ pdf)'
        },
        {
            code:'databaseContactPoint',
            label: 'Database contact point',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code:'typeOfData',
            label: 'Type of data',
            dataType : 'enum',
            widgetType: 'radio',
            multiselect: false,
            options: [
                {value: 'raw', label:'Raw'},
                {value: 'aggregated', label:'Aggregated'}
            ]
        },

    ]
}

export const laboratories = {
    code: 'laboratories',
    label: 'Laboratories',
    type: 'repeating_group',
    isValid: true,
    isDisabled: false,
    fields: [
        {
            code: 'laboratories_info',
            label : 'Which laboratories analyse collected samples?',
            dataType : 'heading',
        },
        {
            code:'fullNameOfTheLaboratory',
            label: 'Full name of the laboratory',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code:'laboratoryInstitution',
            label: 'Institution',
            hint: 'Institution under which this laboratory belongs to',
            dataType: 'string',
            widgetType: 'input'
        },
        {
            code: 'laboratory_country',
            label: 'Laboratory country',
            dataType : 'enum',
            widgetType: 'autocomplete',
            chips: true,
            multiselect: true,
            options: [
                { label: "Afghanistan", value: "AFG" },
                { label: "Åland Islands", value: "ALA" },
                { label: "Albania", value: "ALB" },
                { label: "Algeria", value: "DZA" },
                { label: "American Samoa", value: "ASM" },
                { label: "Andorra", value: "AND" },
                { label: "Angola", value: "AGO" },
                { label: "Anguilla", value: "AIA" },
                { label: "Antarctica", value: "ATA" },
                { label: "Antigua and Barbuda", value: "ATG" },
                { label: "Argentina", value: "ARG" },
                { label: "Armenia", value: "ARM" },
                { label: "Aruba", value: "ABW" },
                { label: "Australia", value: "AUS" },
                { label: "Austria", value: "AUT" },
                { label: "Azerbaijan", value: "AZE" },
                { label: "Bahamas", value: "BHS" },
                { label: "Bahrain", value: "BHR" },
                { label: "Bangladesh", value: "BGD" },
                { label: "Barbados", value: "BRB" },
                { label: "Belarus", value: "BLR" },
                { label: "Belgium", value: "BEL" },
                { label: "Belize", value: "BLZ" },
                { label: "Benin", value: "BEN" },
                { label: "Bermuda", value: "BMU" },
                { label: "Bhutan", value: "BTN" },
                { label: "Bolivia, Plurinational State of", value: "BOL" },
                { label: "Bosnia and Herzegovina", value: "BIH" },
                { label: "Botswana", value: "BWA" },
                { label: "Bouvet Island", value: "BVT" },
                { label: "Brazil", value: "BRA" },
                { label: "British Indian Ocean Territory", value: "IOT" },
                { label: "Brunei Darussalam", value: "BRN" },
                { label: "Bulgaria", value: "BGR" },
                { label: "Burkina Faso", value: "BFA" },
                { label: "Burundi", value: "BDI" },
                { label: "Cambodia", value: "KHM" },
                { label: "Cameroon", value: "CMR" },
                { label: "Canada", value: "CAN" },
                { label: "Cape Verde", value: "CPV" },
                { label: "Cayman Islands", value: "CYM" },
                { label: "Central African Republic", value: "CAF" },
                { label: "Chad", value: "TCD" },
                { label: "Chile", value: "CHL" },
                { label: "China", value: "CHN" },
                { label: "Christmas Island", value: "CXR" },
                { label: "Cocos (Keeling) Islands", value: "CCK" },
                { label: "Colombia", value: "COL" },
                { label: "Comoros", value: "COM" },
                { label: "Congo", value: "COG" },
                { label: "Congo, the Democratic Republic of the", value: "COD" },
                { label: "Cook Islands", value: "COK" },
                { label: "Costa Rica", value: "CRI" },
                { label: "Côte d'Ivoire", value: "CIV" },
                { label: "Croatia", value: "HRV" },
                { label: "Cuba", value: "CUB" },
                { label: "Cyprus", value: "CYP" },
                { label: "Czech Republic", value: "CZE" },
                { label: "Denmark", value: "DNK" },
                { label: "Djibouti", value: "DJI" },
                { label: "Dominica", value: "DMA" },
                { label: "Dominican Republic", value: "DOM" },
                { label: "Ecuador", value: "ECU" },
                { label: "Egypt", value: "EGY" },
                { label: "El Salvador", value: "SLV" },
                { label: "Equatorial Guinea", value: "GNQ" },
                { label: "Eritrea", value: "ERI" },
                { label: "Estonia", value: "EST" },
                { label: "Ethiopia", value: "ETH" },
                { label: "Falkland Islands (Malvinas)", value: "FLK" },
                { label: "Faroe Islands", value: "FRO" },
                { label: "Fiji", value: "FJI" },
                { label: "Finland", value: "FIN" },
                { label: "France", value: "FRA" },
                { label: "French Guiana", value: "GUF" },
                { label: "French Polynesia", value: "PYF" },
                { label: "French Southern Territories", value: "ATF" },
                { label: "Gabon", value: "GAB" },
                { label: "Gambia", value: "GMB" },
                { label: "Georgia", value: "GEO" },
                { label: "Germany", value: "DEU" },
                { label: "Ghana", value: "GHA" },
                { label: "Gibraltar", value: "GIB" },
                { label: "Greece", value: "GRC" },
                { label: "Greenland", value: "GRL" },
                { label: "Grenada", value: "GRD" },
                { label: "Guadeloupe", value: "GLP" },
                { label: "Guam", value: "GUM" },
                { label: "Guatemala", value: "GTM" },
                { label: "Guernsey", value: "GGY" },
                { label: "Guinea", value: "GIN" },
                { label: "Guinea-Bissau", value: "GNB" },
                { label: "Guyana", value: "GUY" },
                { label: "Haiti", value: "HTI" },
                { label: "Heard Island and McDonald Islands", value: "HMD" },
                { label: "Holy See (Vatican City State)", value: "VAT" },
                { label: "Honduras", value: "HND" },
                { label: "Hong Kong", value: "HKG" },
                { label: "Hungary", value: "HUN" },
                { label: "Iceland", value: "ISL" },
                { label: "India", value: "IND" },
                { label: "Indonesia", value: "IDN" },
                { label: "Iran, Islamic Republic of", value: "IRN" },
                { label: "Iraq", value: "IRQ" },
                { label: "Ireland", value: "IRL" },
                { label: "Isle of Man", value: "IMN" },
                { label: "Israel", value: "ISR" },
                { label: "Italy", value: "ITA" },
                { label: "Jamaica", value: "JAM" },
                { label: "Japan", value: "JPN" },
                { label: "Jersey", value: "JEY" },
                { label: "Jordan", value: "JOR" },
                { label: "Kazakhstan", value: "KAZ" },
                { label: "Kenya", value: "KEN" },
                { label: "Kiribati", value: "KIR" },
                { label: "Korea, Democratic People's Republic of", value: "PRK" },
                { label: "Korea, Republic of", value: "KOR" },
                { label: "Kuwait", value: "KWT" },
                { label: "Kyrgyzstan", value: "KGZ" },
                { label: "Lao People's Democratic Republic", value: "LAO" },
                { label: "Latvia", value: "LVA" },
                { label: "Lebanon", value: "LBN" },
                { label: "Lesotho", value: "LSO" },
                { label: "Liberia", value: "LBR" },
                { label: "Libyan Arab Jamahiriya", value: "LBY" },
                { label: "Liechtenstein", value: "LIE" },
                { label: "Lithuania", value: "LTU" },
                { label: "Luxembourg", value: "LUX" },
                { label: "Macao", value: "MAC" },
                { label: "Macedonia, the former Yugoslav Republic of", value: "MKD" },
                { label: "Madagascar", value: "MDG" },
                { label: "Malawi", value: "MWI" },
                { label: "Malaysia", value: "MYS" },
                { label: "Maldives", value: "MDV" },
                { label: "Mali", value: "MLI" },
                { label: "Malta", value: "MLT" },
                { label: "Marshall Islands", value: "MHL" },
                { label: "Martinique", value: "MTQ" },
                { label: "Mauritania", value: "MRT" },
                { label: "Mauritius", value: "MUS" },
                { label: "Mayotte", value: "MYT" },
                { label: "Mexico", value: "MEX" },
                { label: "Micronesia, Federated States of", value: "FSM" },
                { label: "Moldova, Republic of", value: "MDA" },
                { label: "Monaco", value: "MCO" },
                { label: "Mongolia", value: "MNG" },
                { label: "Montenegro", value: "MNE" },
                { label: "Montserrat", value: "MSR" },
                { label: "Morocco", value: "MAR" },
                { label: "Mozambique", value: "MOZ" },
                { label: "Myanmar", value: "MMR" },
                { label: "Namibia", value: "NAM" },
                { label: "Nauru", value: "NRU" },
                { label: "Nepal", value: "NPL" },
                { label: "Netherlands", value: "NLD" },
                { label: "Netherlands Antilles", value: "ANT" },
                { label: "New Caledonia", value: "NCL" },
                { label: "New Zealand", value: "NZL" },
                { label: "Nicaragua", value: "NIC" },
                { label: "Niger", value: "NER" },
                { label: "Nigeria", value: "NGA" },
                { label: "Niue", value: "NIU" },
                { label: "Norfolk Island", value: "NFK" },
                { label: "Northern Mariana Islands", value: "MNP" },
                { label: "Norway", value: "NOR" },
                { label: "Oman", value: "OMN" },
                { label: "Pakistan", value: "PAK" },
                { label: "Palau", value: "PLW" },
                { label: "Palestinian Territory, Occupied", value: "PSE" },
                { label: "Panama", value: "PAN" },
                { label: "Papua New Guinea", value: "PNG" },
                { label: "Paraguay", value: "PRY" },
                { label: "Peru", value: "PER" },
                { label: "Philippines", value: "PHL" },
                { label: "Pitcairn", value: "PCN" },
                { label: "Poland", value: "POL" },
                { label: "Portugal", value: "PRT" },
                { label: "Puerto Rico", value: "PRI" },
                { label: "Qatar", value: "QAT" },
                { label: "Réunion", value: "REU" },
                { label: "Romania", value: "ROU" },
                { label: "Russian Federation", value: "RUS" },
                { label: "Rwanda", value: "RWA" },
                { label: "Saint Barthélemy", value: "BLM" },
                { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
                { label: "Saint Kitts and Nevis", value: "KNA" },
                { label: "Saint Lucia", value: "LCA" },
                { label: "Saint Martin (French part)", value: "MAF" },
                { label: "Saint Pierre and Miquelon", value: "SPM" },
                { label: "Saint Vincent and the Grenadines", value: "VCT" },
                { label: "Samoa", value: "WSM" },
                { label: "San Marino", value: "SMR" },
                { label: "Sao Tome and Principe", value: "STP" },
                { label: "Saudi Arabia", value: "SAU" },
                { label: "Senegal", value: "SEN" },
                { label: "Serbia", value: "SRB" },
                { label: "Seychelles", value: "SYC" },
                { label: "Sierra Leone", value: "SLE" },
                { label: "Singapore", value: "SGP" },
                { label: "Slovakia", value: "SVK" },
                { label: "Slovenia", value: "SVN" },
                { label: "Solomon Islands", value: "SLB" },
                { label: "Somalia", value: "SOM" },
                { label: "South Africa", value: "ZAF" },
                { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
                { label: "Spain", value: "ESP" },
                { label: "Sri Lanka", value: "LKA" },
                { label: "Sudan", value: "SDN" },
                { label: "Suriname", value: "SUR" },
                { label: "Svalbard and Jan Mayen", value: "SJM" },
                { label: "Swaziland", value: "SWZ" },
                { label: "Sweden", value: "SWE" },
                { label: "Switzerland", value: "CHE" },
                { label: "Syrian Arab Republic", value: "SYR" },
                { label: "Taiwan, Province of China", value: "TWN" },
                { label: "Tajikistan", value: "TJK" },
                { label: "Tanzania, United Republic of", value: "TZA" },
                { label: "Thailand", value: "THA" },
                { label: "Timor-Leste", value: "TLS" },
                { label: "Togo", value: "TGO" },
                { label: "Tokelau", value: "TKL" },
                { label: "Tonga", value: "TON" },
                { label: "Trinidad and Tobago", value: "TTO" },
                { label: "Tunisia", value: "TUN" },
                { label: "Turkey", value: "TUR" },
                { label: "Turkmenistan", value: "TKM" },
                { label: "Turks and Caicos Islands", value: "TCA" },
                { label: "Tuvalu", value: "TUV" },
                { label: "Uganda", value: "UGA" },
                { label: "Ukraine", value: "UKR" },
                { label: "United Arab Emirates", value: "ARE" },
                { label: "United Kingdom", value: "GBR" },
                { label: "United States", value: "USA" },
                { label: "United States Minor Outlying Islands", value: "UMI" },
                { label: "Uruguay", value: "URY" },
                { label: "Uzbekistan", value: "UZB" },
                { label: "Vanuatu", value: "VUT" },
                { label: "Venezuela, Bolivarian Republic of", value: "VEN" },
                { label: "Viet Nam", value: "VNM" },
                { label: "Virgin Islands, British", value: "VGB" },
                { label: "Virgin Islands, U.S.", value: "VIR" },
                { label: "Wallis and Futuna", value: "WLF" },
                { label: "Western Sahara", value: "ESH" },
                { label: "Yemen", value: "YEM" },
                { label: "Zambia", value: "ZMB" },
                { label: "Zimbabwe", value: "ZWE" }
            ]

        },
    ]
}

export const monitoring_description = {
    code: 'monitoring_description',
    label: 'Monitoring Description',
    type: 'group',
    isValid: true,
    isDisabled: false,
    fields: [
        {
            code: 'monitoring_description_info',
            label : 'Please provide basic information on the geographical and temporal extent of the monitoring.',
            dataType : 'heading',
        },
        {
            code: 'startOfMonitoring',
            label: 'Start of monitoring (year)',
            dataType: 'string',
            widgetType: 'input'

        },
        {
            code: 'endOfMonitoring',
            label: 'End of monitoring (year/ongoing)',
            dataType: 'string',
            widgetType: 'input'

        },
        {
            code: 'geographicalScale',
            label: 'Geographical scale ',
            dataType : 'enum',
            widgetType: 'checkbox',
            multiselect: true,
            options: [
                {value: 'regional', label: 'Regional'},
                {value: 'national', label: 'National'},
                {value: 'european', label: 'European'},
                {value: 'global', label: 'Global'},
            ]

        },
        {
            code: 'sites',
            label: 'Countries',
            dataType : 'enum',
            widgetType: 'autocomplete',
            chips: true,
            multiselect: true,
            options: [
                { label: "Afghanistan", value: "AFG" },
                { label: "Åland Islands", value: "ALA" },
                { label: "Albania", value: "ALB" },
                { label: "Algeria", value: "DZA" },
                { label: "American Samoa", value: "ASM" },
                { label: "Andorra", value: "AND" },
                { label: "Angola", value: "AGO" },
                { label: "Anguilla", value: "AIA" },
                { label: "Antarctica", value: "ATA" },
                { label: "Antigua and Barbuda", value: "ATG" },
                { label: "Argentina", value: "ARG" },
                { label: "Armenia", value: "ARM" },
                { label: "Aruba", value: "ABW" },
                { label: "Australia", value: "AUS" },
                { label: "Austria", value: "AUT" },
                { label: "Azerbaijan", value: "AZE" },
                { label: "Bahamas", value: "BHS" },
                { label: "Bahrain", value: "BHR" },
                { label: "Bangladesh", value: "BGD" },
                { label: "Barbados", value: "BRB" },
                { label: "Belarus", value: "BLR" },
                { label: "Belgium", value: "BEL" },
                { label: "Belize", value: "BLZ" },
                { label: "Benin", value: "BEN" },
                { label: "Bermuda", value: "BMU" },
                { label: "Bhutan", value: "BTN" },
                { label: "Bolivia, Plurinational State of", value: "BOL" },
                { label: "Bosnia and Herzegovina", value: "BIH" },
                { label: "Botswana", value: "BWA" },
                { label: "Bouvet Island", value: "BVT" },
                { label: "Brazil", value: "BRA" },
                { label: "British Indian Ocean Territory", value: "IOT" },
                { label: "Brunei Darussalam", value: "BRN" },
                { label: "Bulgaria", value: "BGR" },
                { label: "Burkina Faso", value: "BFA" },
                { label: "Burundi", value: "BDI" },
                { label: "Cambodia", value: "KHM" },
                { label: "Cameroon", value: "CMR" },
                { label: "Canada", value: "CAN" },
                { label: "Cape Verde", value: "CPV" },
                { label: "Cayman Islands", value: "CYM" },
                { label: "Central African Republic", value: "CAF" },
                { label: "Chad", value: "TCD" },
                { label: "Chile", value: "CHL" },
                { label: "China", value: "CHN" },
                { label: "Christmas Island", value: "CXR" },
                { label: "Cocos (Keeling) Islands", value: "CCK" },
                { label: "Colombia", value: "COL" },
                { label: "Comoros", value: "COM" },
                { label: "Congo", value: "COG" },
                { label: "Congo, the Democratic Republic of the", value: "COD" },
                { label: "Cook Islands", value: "COK" },
                { label: "Costa Rica", value: "CRI" },
                { label: "Côte d'Ivoire", value: "CIV" },
                { label: "Croatia", value: "HRV" },
                { label: "Cuba", value: "CUB" },
                { label: "Cyprus", value: "CYP" },
                { label: "Czech Republic", value: "CZE" },
                { label: "Denmark", value: "DNK" },
                { label: "Djibouti", value: "DJI" },
                { label: "Dominica", value: "DMA" },
                { label: "Dominican Republic", value: "DOM" },
                { label: "Ecuador", value: "ECU" },
                { label: "Egypt", value: "EGY" },
                { label: "El Salvador", value: "SLV" },
                { label: "Equatorial Guinea", value: "GNQ" },
                { label: "Eritrea", value: "ERI" },
                { label: "Estonia", value: "EST" },
                { label: "Ethiopia", value: "ETH" },
                { label: "Falkland Islands (Malvinas)", value: "FLK" },
                { label: "Faroe Islands", value: "FRO" },
                { label: "Fiji", value: "FJI" },
                { label: "Finland", value: "FIN" },
                { label: "France", value: "FRA" },
                { label: "French Guiana", value: "GUF" },
                { label: "French Polynesia", value: "PYF" },
                { label: "French Southern Territories", value: "ATF" },
                { label: "Gabon", value: "GAB" },
                { label: "Gambia", value: "GMB" },
                { label: "Georgia", value: "GEO" },
                { label: "Germany", value: "DEU" },
                { label: "Ghana", value: "GHA" },
                { label: "Gibraltar", value: "GIB" },
                { label: "Greece", value: "GRC" },
                { label: "Greenland", value: "GRL" },
                { label: "Grenada", value: "GRD" },
                { label: "Guadeloupe", value: "GLP" },
                { label: "Guam", value: "GUM" },
                { label: "Guatemala", value: "GTM" },
                { label: "Guernsey", value: "GGY" },
                { label: "Guinea", value: "GIN" },
                { label: "Guinea-Bissau", value: "GNB" },
                { label: "Guyana", value: "GUY" },
                { label: "Haiti", value: "HTI" },
                { label: "Heard Island and McDonald Islands", value: "HMD" },
                { label: "Holy See (Vatican City State)", value: "VAT" },
                { label: "Honduras", value: "HND" },
                { label: "Hong Kong", value: "HKG" },
                { label: "Hungary", value: "HUN" },
                { label: "Iceland", value: "ISL" },
                { label: "India", value: "IND" },
                { label: "Indonesia", value: "IDN" },
                { label: "Iran, Islamic Republic of", value: "IRN" },
                { label: "Iraq", value: "IRQ" },
                { label: "Ireland", value: "IRL" },
                { label: "Isle of Man", value: "IMN" },
                { label: "Israel", value: "ISR" },
                { label: "Italy", value: "ITA" },
                { label: "Jamaica", value: "JAM" },
                { label: "Japan", value: "JPN" },
                { label: "Jersey", value: "JEY" },
                { label: "Jordan", value: "JOR" },
                { label: "Kazakhstan", value: "KAZ" },
                { label: "Kenya", value: "KEN" },
                { label: "Kiribati", value: "KIR" },
                { label: "Korea, Democratic People's Republic of", value: "PRK" },
                { label: "Korea, Republic of", value: "KOR" },
                { label: "Kuwait", value: "KWT" },
                { label: "Kyrgyzstan", value: "KGZ" },
                { label: "Lao People's Democratic Republic", value: "LAO" },
                { label: "Latvia", value: "LVA" },
                { label: "Lebanon", value: "LBN" },
                { label: "Lesotho", value: "LSO" },
                { label: "Liberia", value: "LBR" },
                { label: "Libyan Arab Jamahiriya", value: "LBY" },
                { label: "Liechtenstein", value: "LIE" },
                { label: "Lithuania", value: "LTU" },
                { label: "Luxembourg", value: "LUX" },
                { label: "Macao", value: "MAC" },
                { label: "Macedonia, the former Yugoslav Republic of", value: "MKD" },
                { label: "Madagascar", value: "MDG" },
                { label: "Malawi", value: "MWI" },
                { label: "Malaysia", value: "MYS" },
                { label: "Maldives", value: "MDV" },
                { label: "Mali", value: "MLI" },
                { label: "Malta", value: "MLT" },
                { label: "Marshall Islands", value: "MHL" },
                { label: "Martinique", value: "MTQ" },
                { label: "Mauritania", value: "MRT" },
                { label: "Mauritius", value: "MUS" },
                { label: "Mayotte", value: "MYT" },
                { label: "Mexico", value: "MEX" },
                { label: "Micronesia, Federated States of", value: "FSM" },
                { label: "Moldova, Republic of", value: "MDA" },
                { label: "Monaco", value: "MCO" },
                { label: "Mongolia", value: "MNG" },
                { label: "Montenegro", value: "MNE" },
                { label: "Montserrat", value: "MSR" },
                { label: "Morocco", value: "MAR" },
                { label: "Mozambique", value: "MOZ" },
                { label: "Myanmar", value: "MMR" },
                { label: "Namibia", value: "NAM" },
                { label: "Nauru", value: "NRU" },
                { label: "Nepal", value: "NPL" },
                { label: "Netherlands", value: "NLD" },
                { label: "Netherlands Antilles", value: "ANT" },
                { label: "New Caledonia", value: "NCL" },
                { label: "New Zealand", value: "NZL" },
                { label: "Nicaragua", value: "NIC" },
                { label: "Niger", value: "NER" },
                { label: "Nigeria", value: "NGA" },
                { label: "Niue", value: "NIU" },
                { label: "Norfolk Island", value: "NFK" },
                { label: "Northern Mariana Islands", value: "MNP" },
                { label: "Norway", value: "NOR" },
                { label: "Oman", value: "OMN" },
                { label: "Pakistan", value: "PAK" },
                { label: "Palau", value: "PLW" },
                { label: "Palestinian Territory, Occupied", value: "PSE" },
                { label: "Panama", value: "PAN" },
                { label: "Papua New Guinea", value: "PNG" },
                { label: "Paraguay", value: "PRY" },
                { label: "Peru", value: "PER" },
                { label: "Philippines", value: "PHL" },
                { label: "Pitcairn", value: "PCN" },
                { label: "Poland", value: "POL" },
                { label: "Portugal", value: "PRT" },
                { label: "Puerto Rico", value: "PRI" },
                { label: "Qatar", value: "QAT" },
                { label: "Réunion", value: "REU" },
                { label: "Romania", value: "ROU" },
                { label: "Russian Federation", value: "RUS" },
                { label: "Rwanda", value: "RWA" },
                { label: "Saint Barthélemy", value: "BLM" },
                { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
                { label: "Saint Kitts and Nevis", value: "KNA" },
                { label: "Saint Lucia", value: "LCA" },
                { label: "Saint Martin (French part)", value: "MAF" },
                { label: "Saint Pierre and Miquelon", value: "SPM" },
                { label: "Saint Vincent and the Grenadines", value: "VCT" },
                { label: "Samoa", value: "WSM" },
                { label: "San Marino", value: "SMR" },
                { label: "Sao Tome and Principe", value: "STP" },
                { label: "Saudi Arabia", value: "SAU" },
                { label: "Senegal", value: "SEN" },
                { label: "Serbia", value: "SRB" },
                { label: "Seychelles", value: "SYC" },
                { label: "Sierra Leone", value: "SLE" },
                { label: "Singapore", value: "SGP" },
                { label: "Slovakia", value: "SVK" },
                { label: "Slovenia", value: "SVN" },
                { label: "Solomon Islands", value: "SLB" },
                { label: "Somalia", value: "SOM" },
                { label: "South Africa", value: "ZAF" },
                { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
                { label: "Spain", value: "ESP" },
                { label: "Sri Lanka", value: "LKA" },
                { label: "Sudan", value: "SDN" },
                { label: "Suriname", value: "SUR" },
                { label: "Svalbard and Jan Mayen", value: "SJM" },
                { label: "Swaziland", value: "SWZ" },
                { label: "Sweden", value: "SWE" },
                { label: "Switzerland", value: "CHE" },
                { label: "Syrian Arab Republic", value: "SYR" },
                { label: "Taiwan, Province of China", value: "TWN" },
                { label: "Tajikistan", value: "TJK" },
                { label: "Tanzania, United Republic of", value: "TZA" },
                { label: "Thailand", value: "THA" },
                { label: "Timor-Leste", value: "TLS" },
                { label: "Togo", value: "TGO" },
                { label: "Tokelau", value: "TKL" },
                { label: "Tonga", value: "TON" },
                { label: "Trinidad and Tobago", value: "TTO" },
                { label: "Tunisia", value: "TUN" },
                { label: "Turkey", value: "TUR" },
                { label: "Turkmenistan", value: "TKM" },
                { label: "Turks and Caicos Islands", value: "TCA" },
                { label: "Tuvalu", value: "TUV" },
                { label: "Uganda", value: "UGA" },
                { label: "Ukraine", value: "UKR" },
                { label: "United Arab Emirates", value: "ARE" },
                { label: "United Kingdom", value: "GBR" },
                { label: "United States", value: "USA" },
                { label: "United States Minor Outlying Islands", value: "UMI" },
                { label: "Uruguay", value: "URY" },
                { label: "Uzbekistan", value: "UZB" },
                { label: "Vanuatu", value: "VUT" },
                { label: "Venezuela, Bolivarian Republic of", value: "VEN" },
                { label: "Viet Nam", value: "VNM" },
                { label: "Virgin Islands, British", value: "VGB" },
                { label: "Virgin Islands, U.S.", value: "VIR" },
                { label: "Wallis and Futuna", value: "WLF" },
                { label: "Western Sahara", value: "ESH" },
                { label: "Yemen", value: "YEM" },
                { label: "Zambia", value: "ZMB" },
                { label: "Zimbabwe", value: "ZWE" }
            ]

        },
        {
            code: 'numberOfSites',
            label: 'Number of sampling sites',
            dataType: 'string',
            widgetType: 'input'

        },
        {
            code: 'siteCoordinates',
            label: 'Site coordinates',
            dataType : 'enum',
            widgetType: 'radio',
            multiselect: false,
            options: [
                {value: 'yes', label: 'Yes'},
                {value: 'no', label: 'No'},
              ]

        },
        ]
}







export const bisphenols_section = {code : 'bisphenols', label: 'Bisphenols', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, bisphenols.parameter, sectionCommon.other_parameter]}
export const elements_section = {code : 'elements', label: 'Elements', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, elements.parameter, sectionCommon.other_parameter]}
export const hbcds_section = {code : 'hbcds', label: 'HBCDs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, hbcds.parameter, sectionCommon.other_parameter]}
export const hcbd_section = {code : 'hcbd', label: 'HCBD', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, hcbd.parameter, sectionCommon.other_parameter]}
export const microcystins_section = {code : 'microcystins', label: 'Microcystins', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, microcystins.parameter, sectionCommon.other_parameter]}
export const musks_section = {code : 'musks', label: 'Musks', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, musks.parameter, sectionCommon.other_parameter]}
export const nfrs_section = {code : 'nfrs', label: 'NFRs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, nfrs.parameter, sectionCommon.other_parameter]}
export const opfrs_section = {code : 'opfrs', label: 'OPFRs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, opfrs.parameter, sectionCommon.other_parameter]}
export const pahs_section = {code : 'pahs', label: 'PAHs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pahs.parameter, sectionCommon.other_parameter]}
export const pahs_substituted_section = {code : 'pahs_substituted', label: 'PAHs - substituted', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pahs_substituted.parameter, sectionCommon.other_parameter]}
export const paraffins_section = {code : 'paraffins', label: 'Paraffins', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, paraffins.parameter, sectionCommon.other_parameter]}
export const pbbs_section = {code : 'pbbs', label: 'PBBs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pbbs.parameter, sectionCommon.other_parameter]}
export const pbdes_section = {code : 'pbdes', label: 'PBDEs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pbdes.parameter, sectionCommon.other_parameter]}
export const pcbs_section = {code : 'pcbs', label: 'PCBs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pcbs.parameter, sectionCommon.other_parameter]}
export const pcbs_dl_section = {code : 'pcbs_dl', label: 'PCBs-dl', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pcbs_dl.parameter, sectionCommon.other_parameter]}
export const pcdds_fs_section = {code : 'pcdds_fs', label: 'PCDDs/Fs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pcdds_fs.parameter, sectionCommon.other_parameter]}
export const pcns_section = {code : 'pcns', label: 'PCNs', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pcns.parameter, sectionCommon.other_parameter]}
export const pesticides_basic_section = {code : 'pesticides_basic', label: 'Pesticides - basic', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pesticides_basic.parameter, sectionCommon.other_parameter]}
export const pesticides_cups_section = {code : 'pesticides_cups', label: 'Pesticides - CUP', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pesticides_cups.parameter, sectionCommon.other_parameter]}
export const pesticides_drines_section = {code : 'pesticides_drines', label: 'Pesticides - drines', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pesticides_drines.parameter, sectionCommon.other_parameter]}
export const pesticides_others_section = {code : 'pesticides_others', label: 'Pesticides - other', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pesticides_others.parameter, sectionCommon.other_parameter]}
export const pesticides_pyrethroids_section = {code : 'pesticides_pyrethroids', label: 'Pesticides - pyrethroids', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pesticides_pyrethroids.parameter, sectionCommon.other_parameter]}
export const pfas_section = {code : 'pfas', label: 'PFAS', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pfas.parameter, sectionCommon.other_parameter]}
export const pharmaceuticals_section = {code : 'pharmaceuticals', label: 'Pharmaceuticals', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, pharmaceuticals.parameter, sectionCommon.other_parameter]}
export const phthalates_section = {code : 'phthalates', label: 'Phthalates', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, phthalates.parameter, sectionCommon.other_parameter]}
export const sweeteners_section = {code : 'sweeteners', label: 'Sweeteners', type: 'repeating_group', isValid: true, isDisabled: false, fields: [sectionCommon.section_info, sectionCommon.section_matrix, sweeteners.parameter, sectionCommon.other_parameter]}


export const final_comments_section = {
    code: 'final_comments',
    label: 'Final Comments',
    type: 'group',
    isValid: true,
    isDisabled: false,
    fields: [
        {
            code: 'finalCommen',
            label: 'If you have any final comment you would like to add, please feel free to use this space.',
            dataType : 'text',
            widgetType: 'textarea'

        }
    ]
}
