export const parameter = {
    code: 'parameter',
    label : 'NFRs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        

{ group: "NFRs", label: "ATE (3278-89-5)", value: "ATE (3278-89-5)" },
{ group: "NFRs", label: "BATE (3278-89-5)", value: "BATE (3278-89-5)" },
{ group: "NFRs", label: "BEHTBP (26040-51-7)", value: "BEHTBP (26040-51-7)" },
{ group: "NFRs", label: "BTBPE (37853-59-1)", value: "BTBPE (37853-59-1)" },
{ group: "NFRs", label: "DBDPE (84852-53-9)", value: "DBDPE (84852-53-9)" },
{ group: "NFRs", label: "DP-anti (13560-89-9)", value: "DP-anti (13560-89-9)" },
{ group: "NFRs", label: "DPMA (135821-04-4)", value: "DPMA (135821-04-4)" },
{ group: "NFRs", label: "DP-syn (13560-89-9)", value: "DP-syn (13560-89-9)" },
{ group: "NFRs", label: "DPTE (35109-60-5)", value: "DPTE (35109-60-5)" },
{ group: "NFRs", label: "EHTBB (183658-27-7)", value: "EHTBB (183658-27-7)" },
{ group: "NFRs", label: "HBB (87-82-1)", value: "HBB (87-82-1)" },
{ group: "NFRs", label: "HCDBCO (51936-55-1)", value: "HCDBCO (51936-55-1)" },
{ group: "NFRs", label: "PBBA (59947-55-1)", value: "PBBA (59947-55-1)" },
{ group: "NFRs", label: "PBBZ (608-90-2)", value: "PBBZ (608-90-2)" },
{ group: "NFRs", label: "PBEB (85-22-3)", value: "PBEB (85-22-3)" },
{ group: "NFRs", label: "PBT (87-83-2)", value: "PBT (87-83-2)" },
{ group: "NFRs", label: "T23BPIC (52434-90-9)", value: "T23BPIC (52434-90-9)" },
{ group: "NFRs", label: "TBCO-alpha (3194-57-8)", value: "TBCO-alpha (3194-57-8)" },
{ group: "NFRs", label: "TBCO-beta (3194-57-8)", value: "TBCO-beta (3194-57-8)" },
{ group: "NFRs", label: "TBECH-alpha (3322-93-8)", value: "TBECH-alpha (3322-93-8)" },
{ group: "NFRs", label: "TBECH-beta (3322-93-8)", value: "TBECH-beta (3322-93-8)" },
{ group: "NFRs", label: "TB-p-xylene (23488-38-2)", value: "TB-p-xylene (23488-38-2)" },
{ group: "NFRs", label: "TBCT (39569-21-6)", value: "TBCT (39569-21-6)" },


    ]
}
