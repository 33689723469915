export const parameter = {
    code: 'pyrethroids',
    label : 'Pyrethroids',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        
{ group: "Pesticides - pyrethroids", label: "bifenthrin (82657-04-3)", value: "bifenthrin (82657-04-3)" },
{ group: "Pesticides - pyrethroids", label: "cyfluthrin (68359-37-5)", value: "cyfluthrin (68359-37-5)" },
{ group: "Pesticides - pyrethroids", label: "cypermethrin (52315-07-8)", value: "cypermethrin (52315-07-8)" },
{ group: "Pesticides - pyrethroids", label: "fenvalerate (51630-58-1)", value: "fenvalerate (51630-58-1)" },
{ group: "Pesticides - pyrethroids", label: "lambda-Cyhalothrin (91465-08-6)", value: "lambda-Cyhalothrin (91465-08-6)" },
{ group: "Pesticides - pyrethroids", label: "tau-Fluvinate (102851-06-9)", value: "tau-Fluvinate (102851-06-9)" },
{ group: "Pesticides - pyrethroids", label: "tetramethrin (7696-12-0)", value: "tetramethrin (7696-12-0)" },


    ]
}
