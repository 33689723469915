<template>
  <v-app>
    <v-snackbar v-model="snackbar" :color="`${snackbarColor}`" :timeout="snackbarTimeout ? snackbarTimeout : 5000" class="pa-0" top tile>
      <v-container fluid>
        <v-row>
          <v-col v-html="snackbarText"> </v-col>
        </v-row>
      </v-container>
      <template v-slot:action="{ attrs }">
        <v-container fluid>
          <v-row>
            <v-col cols="auto">
              <v-btn v-if="snackbarButton" tile small color="success" v-bind="attrs">OK</v-btn>
              <v-icon v-if="snackbarIco" color="white" right>{{ snackbarIco }}</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-snackbar>

    <v-app-bar app color="primary" dark hide-on-scroll>
      <v-toolbar-title>
        <router-link to="/" class="white--text text-decoration-none">
          {{ $vuetify.lang.t("$vuetify.title") }}
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view :fluid-prop="fluidProp" @show-snack="showSnack" />
    </v-main>
  </v-app>
</template>

<script>
  //import * as forms from "./components/form/config/formConfig";
  //import { departments } from "./components/form/config/departments";
  export default {
    name: "App",
    data() {
      return {
        appVersion: process.env.VUE_APP_VERSION,
        snackbarText: "",
        snackbarColor: "",
        snackbarIco: "",
        snackbar: false,
        snackbarButton: false,
        snackbarTimeout: 4000,
        fluidProp: false,
      };
    },
    // beforeMount() {
    //   this.$router.replace(
    //     {
    //       path: `/${departments[0].path}/${departments[0].services[0].path}`,
    //       //query: { subDepartment: departments[0].services[0].subDepartment },
    //     },
    //     () => {}
    //   );
    // },
    // computed: {
    //   formConfig() {
    //     // if (this.department && this.service) return forms[`${this.department.path}_${this.service.form}_Config`]
    //     return forms["parc_wp7_env_net_Config"]; // []
    //   },
    // },
    methods: {
      showSnack({ text, color, ico, btn, timeout }) {
        this.snackbarText = text;
        this.snackbarColor = color;
        this.snackbarIco = ico;
        this.snackbar = true;
        this.snackbarButton = btn;
        this.snackbarTimeout = timeout;
      },
    },
  };
</script>
