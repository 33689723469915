import * as formGroups from './formGroupsConfig'

// department: {
//     path
//     service: {
//         path
//         subDepartment
//         form
//     }
// }
// form name = department.path__department.service.form
export const parc_wp7_environmental_networks_Config = [
    {
        code: 'contact_information',
        label: 'Contact information',
        isValid: true,
        groups: [
            formGroups.contact_information,
        ]
    },
    {
        code: 'specification_network',
        label: 'Monitoring network',
        isValid: true,
        groups: [
            formGroups.monitoring_network,
        ]
    },
    {
        code: 'specification_extent',
        label: 'Sampling period and extent',
        isValid: true,
        groups: [
            formGroups.monitoring_description,
        ]
    },
    {
        code: 'specification_database',
        label: 'Database / data repository',
        isValid: true,
        groups: [
            formGroups.database,
        ]
    },
    {
        code: 'specification_lab',
        label: 'Laboratories',
        isValid: true,
        groups: [
            formGroups.laboratories,
        ]
    },
    {code : 'bisphenols', label: 'Bisphenols', isValid: true, groups:[ formGroups.bisphenols_section ]},
    {code : 'elements', label: 'Elements',  isValid: true, groups:[ formGroups.elements_section ]},
    {code : 'hbcds', label: 'HBCDs',  isValid: true, groups:[ formGroups.hbcds_section ]},
    {code : 'hcbd', label: 'HCBD',  isValid: true, groups:[ formGroups.hcbd_section ]},
    {code : 'microcystins', label: 'Microcystins',  isValid: true, groups:[ formGroups.microcystins_section ]},
    {code : 'musks', label: 'Musks',  isValid: true, groups:[ formGroups.musks_section ]},
    {code : 'nfrs', label: 'NFRs',  isValid: true, groups:[ formGroups.nfrs_section ]},
    {code : 'opfrs', label: 'OPFRs',  isValid: true, groups:[ formGroups.opfrs_section ]},
    {code : 'pahs', label: 'PAHs',  isValid: true, groups:[ formGroups.pahs_section ]},
    {code : 'pahs_substituted', label: 'PAHs - substituted', isValid: true, groups:[ formGroups.pahs_substituted_section ]},
    {code : 'paraffins', label: 'Paraffins',  isValid: true, groups:[ formGroups.paraffins_section ]},
    {code : 'pbbs', label: 'PBBs',  isValid: true, groups:[ formGroups.pbbs_section ]},
    {code : 'pbdes', label: 'PBDEs',  isValid: true, groups:[ formGroups.pbdes_section ]},
    {code : 'pcbs', label: 'PCBs',  isValid: true, groups:[ formGroups.pcbs_section ]},
    {code : 'pcbs_dl', label: 'PCBs-dl', isValid: true, groups:[ formGroups.pcbs_dl_section ]},
    {code : 'pcdds_fs', label: 'PCDDs/Fs', isValid: true, groups:[ formGroups.pcdds_fs_section ]},
    {code : 'pcns', label: 'PCNs',  isValid: true, groups:[ formGroups.pcns_section ]},
    {code : 'pesticides_basic', label: 'Pesticides - basic', isValid: true, groups:[ formGroups.pesticides_basic_section ]},
    {code : 'pesticides_cups', label: 'Pesticides - CUP', isValid: true, groups:[ formGroups.pesticides_cups_section ]},
    {code : 'pesticides_drines', label: 'Pesticides - drines', isValid: true, groups:[ formGroups.pesticides_drines_section ]},
    {code : 'pesticides_others', label: 'Pesticides - other', isValid: true, groups:[ formGroups.pesticides_others_section ]},
    {code : 'pesticides_pyrethroids', label: 'Pesticides - pyrethroids', isValid: true, groups:[ formGroups.pesticides_pyrethroids_section ]},
    {code : 'pfas', label: 'PFAS',  isValid: true, groups:[ formGroups.pfas_section ]},
    {code : 'pharmaceuticals', label: 'Pharmaceuticals',  isValid: true, groups:[ formGroups.pharmaceuticals_section ]},
    {code : 'phthalates', label: 'Phthalates',  isValid: true, groups:[ formGroups.phthalates_section ]},
    {code : 'sweeteners', label: 'Sweeteners',  isValid: true, groups:[ formGroups.sweeteners_section ]},
    {
        code: 'final_comment',
        label: 'Final Comment',
        isValid: true,
        groups: [
            formGroups.final_comments_section,
        ]
    },

]

//     { code: 'cups', label: 'CUPs', isValid: true, groups: [formGroups.cups_section] },
//     { code: 'pharmaceuticals', label: 'Pharmaceuticals', isValid: true, groups: [formGroups.pharmaceuticals_section] },
//     { code: 'hbcds', label: 'HBCDs', isValid: true, groups: [formGroups.hbcds_section] },
//     { code: 'musks', label: 'Musks', isValid: true, groups: [formGroups.musks_section] },
//     { code: 'nfrs', label: 'NFRs', isValid: true, groups: [formGroups.nfrs_section] },
//     { code: 'pesticides_drines', label: 'pesticides - Drines', isValid: true, groups: [formGroups.pesticides_drines_section] },
//     { code: 'ocps', label: 'OCPs', isValid: true, groups: [formGroups.ocps_section] },
//     { code: 'opfrs', label: 'OPFRs', isValid: true, groups: [formGroups.opfrs_section] },
//     { code: 'pahs', label: 'PAHs', isValid: true, groups: [formGroups.pahs_section] },
//     { code: 'substituted_pahs', label: 'substituted PAHs', isValid: true, groups: [formGroups.substituted_pahs_section] },
//     { code: 'pbdes', label: 'PBDEs', isValid: true, groups: [formGroups.pbdes_section] },
//     { code: 'dl_pcbs', label: 'dl-PCBs', isValid: true, groups: [formGroups.dl_pcbs_section] },
//     { code: 'pcbs', label: 'PCBs', isValid: true, groups: [formGroups.pcbs_section] },
//     { code: 'pcdds_fs', label: 'PCDDs/Fs', isValid: true, groups: [formGroups.pcdds_fs_section] },
//     { code: 'pfas', label: 'PFAS', isValid: true, groups: [formGroups.pfas_section] },
//     { code: 'elements', label: 'Elements', isValid: true, groups: [formGroups.elements_section] },
//     { code: 'pyrethroids', label: 'Pyrethroids', isValid: true, groups: [formGroups.pyrethroids_section] },
// ]
