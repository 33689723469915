export const parameter = {
    code: 'opfrs',
    label : 'OPFRs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "OPFRs", label: "EHDPP (1241-94-7)", value: "EHDPP (1241-94-7)" },
{ group: "OPFRs", label: "CDP (26444-49-5)", value: "CDP (26444-49-5)" },
{ group: "OPFRs", label: "ip-TPP (68937-41-7)", value: "ip-TPP (68937-41-7)" },
{ group: "OPFRs", label: "TBOEP (78-51-3)", value: "TBOEP (78-51-3)" },
{ group: "OPFRs", label: "TBP (126-73-8)", value: "TBP (126-73-8)" },
{ group: "OPFRs", label: "TCEP (115-96-8)", value: "TCEP (115-96-8)" },
{ group: "OPFRs", label: "TCIPP (13674-84-5)", value: "TCIPP (13674-84-5)" },
{ group: "OPFRs", label: "TDBPP (126-72-7)", value: "TDBPP (126-72-7)" },
{ group: "OPFRs", label: "TDCIPP (13674-87-8)", value: "TDCIPP (13674-87-8)" },
{ group: "OPFRs", label: "TEHP (78-42-2)", value: "TEHP (78-42-2)" },
{ group: "OPFRs", label: "TMPP (78-30-8)", value: "TMPP (78-30-8)" },
{ group: "OPFRs", label: "TPhP (115-86-6)", value: "TPhP (115-86-6)" },
{ group: "OPFRs", label: "Tri n-propyl phosphate (513-08-6)", value: "Tri n-propyl phosphate (513-08-6)" },
{ group: "OPFRs", label: "Triethyl phosphate (78-40-0)", value: "Triethyl phosphate (78-40-0)" },


    ]
}
