<template>
  <v-container :fluid="fluidProp">
    <v-row>
      <v-col cols="6">
        <h1>Currently open surveys</h1>

<!--        <v-list flat v-for="department in departments" :key="department.path">-->
<!--            <v-list-item link v-for="service in department.services.filter(ser => ser.isOpen)" :key="service.path">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon>mdi-chevron-right</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>{{ department.label }} - {{ service.label }}</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
<!--        </v-list>-->

        <ul v-for="department in departments" :key="department.path">
          <li v-for="service in department.services.filter(ser => ser.isOpen)" :key="service.path">
            <router-link :to="{ name: 'survey-form-view', params: { department: department.path, service: service.path } }">{{ department.label }} - {{ service.label }}</router-link>
          </li>
        </ul>
      </v-col>
      <v-col cols="6">
        <h1>List of requests by survey</h1>
        <ul v-for="department in departments" :key="department.path">
          <li v-for="service in department.services" :key="service.path">
            <router-link :to="{ name: 'surveys-view', params: { department: department.path, service: service.path } }">{{ department.label }} - {{ service.label }}</router-link>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { departments } from './../components/form/config/departments'
export default {
  name: 'Dashboard',
  props: ['fluidProp'],
  data () {
    return {
      departments: departments
    }
  }
}
</script>
