import cs from 'vuetify/lib/locale/cs'

export default {
  ...cs,
  language: {
    tooltip: 'Změnit jazyk',
    enExt: 'Anglicky',
    csExt: 'Česky'
  },
  logout: {
    tooltip: 'Odhlásit'
  },
  noDepartments: 'Oddělení nejsou definována.',
  noServices: 'Služby nejsou definována.',
  noForm: 'Formulář není definován.',
  noUser: 'Uživatel není přihlášen.',
  dashboard: 'Dashboard',
  pageNotFound: '404: Stránka nenalezena',
  title: 'Dotazníkové průzkumy'
}
