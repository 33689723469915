export const parameter = {
    code: 'sweeteners',
    label : 'Sweeteners',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Sweeteners", label: "acesulfame (33665-90-6)", value: "acesulfame (33665-90-6)" },
{ group: "Sweeteners", label: "aspartame (22839-47-0)", value: "aspartame (22839-47-0)" },
{ group: "Sweeteners", label: "sodium cyclamate (139-05-9)", value: "sodium cyclamate (139-05-9)" },
{ group: "Sweeteners", label: "neohesperidin dihydrochalcone (20702-77-6)", value: "neohesperidin dihydrochalcone (20702-77-6)" },
{ group: "Sweeteners", label: "saccharin (81-07-2)", value: "saccharin (81-07-2)" },
{ group: "Sweeteners", label: "sucralose (56038-13-2)", value: "sucralose (56038-13-2)" },

    ]
}
