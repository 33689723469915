export const departments = [
    {
        label: 'PARC WP7/9',
        path: 'parc_wp7',
        services: [
            {
                label: 'Environmental networks',
                path: 'environmental_networks',
                form: 'environmental_networks',
                subDepartment: '',
                isOpen: true,
                // description: 'Lorem ipsum dolor sit amet dolor sit amet',
                // icon: 'mdi-frequently-asked-questions',
            }
        ]
    }
]
