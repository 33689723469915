export const parameter = {
    code: 'pesticides-drines',
    label : 'Pesticides - Drines',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        

{ group: "Pesticides - drines", label: "aldrin (309-00-2)", value: "aldrin (309-00-2)" },
{ group: "Pesticides - drines", label: "dieldrin (60-57-1)", value: "dieldrin (60-57-1)" },
{ group: "Pesticides - drines", label: "endosulfan I (= alpha) (959-98-8)", value: "endosulfan I (= alpha) (959-98-8)" },
{ group: "Pesticides - drines", label: "endosulfan II (= beta) (33213-65-9)", value: "endosulfan II (= beta) (33213-65-9)" },
{ group: "Pesticides - drines", label: "endosulfansulfate (1031-07-8)", value: "endosulfansulfate (1031-07-8)" },
{ group: "Pesticides - drines", label: "endrin (72-20-8)", value: "endrin (72-20-8)" },
{ group: "Pesticides - drines", label: "endrin aldehyde (7421-93-4)", value: "endrin aldehyde (7421-93-4)" },
{ group: "Pesticides - drines", label: "endrin ketone (53494-70-5)", value: "endrin ketone (53494-70-5)" },
{ group: "Pesticides - drines", label: "heptachlor (76-44-8)", value: "heptachlor (76-44-8)" },
{ group: "Pesticides - drines", label: "heptachlor epoxide cis- (= exo, B) (1024-57-3)", value: "heptachlor epoxide cis- (= exo, B) (1024-57-3)" },
{ group: "Pesticides - drines", label: "heptachlor epoxide trans- (= endo, A) (28044-83-9)", value: "heptachlor epoxide trans- (= endo, A) (28044-83-9)" },
{ group: "Pesticides - drines", label: "chlordane (= alpha, cis) (5103-71-9)", value: "chlordane (= alpha, cis) (5103-71-9)" },
{ group: "Pesticides - drines", label: "chlordane (= gamma,trans) (5103-74-2)", value: "chlordane (= gamma,trans) (5103-74-2)" },
{ group: "Pesticides - drines", label: "chlordecone (143-50-0)", value: "chlordecone (143-50-0)" },
{ group: "Pesticides - drines", label: "isodrin (465-73-6)", value: "isodrin (465-73-6)" },
{ group: "Pesticides - drines", label: "nonachlor (cis) (5103-73-1)", value: "nonachlor (cis) (5103-73-1)" },
{ group: "Pesticides - drines", label: "nonachlor (trans) (39765-80-5)", value: "nonachlor (trans) (39765-80-5)" },
{ group: "Pesticides - drines", label: "oxychlordane (27304-13-8)", value: "oxychlordane (27304-13-8)" },


    ]
}
