export const parameter = {
    code: 'parameter',
    label : 'CUPs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        
{ group: "Pesticides - CUP", label: "acetochlor (34256-82-1)", value: "acetochlor (34256-82-1)" },
{ group: "Pesticides - CUP", label: "alachlor (15972-60-8)", value: "alachlor (15972-60-8)" },
{ group: "Pesticides - CUP", label: "atrazine (1912-24-9)", value: "atrazine (1912-24-9)" },
{ group: "Pesticides - CUP", label: "azinphos-methyl (86-50-0)", value: "azinphos-methyl (86-50-0)" },
{ group: "Pesticides - CUP", label: "carbaryl (63-25-2)", value: "carbaryl (63-25-2)" },
{ group: "Pesticides - CUP", label: "clopyralid (1702-17-6)", value: "clopyralid (1702-17-6)" },
{ group: "Pesticides - CUP", label: "diazinon (333-41-5)", value: "diazinon (333-41-5)" },
{ group: "Pesticides - CUP", label: "dichlofluanid (1085-98-9)", value: "dichlofluanid (1085-98-9)" },
{ group: "Pesticides - CUP", label: "dimethachlor (50563-36-5)", value: "dimethachlor (50563-36-5)" },
{ group: "Pesticides - CUP", label: "dimethoate (60-51-5)", value: "dimethoate (60-51-5)" },
{ group: "Pesticides - CUP", label: "disulfoton (298-04-4)", value: "disulfoton (298-04-4)" },
{ group: "Pesticides - CUP", label: "diuron (330-54-1)", value: "diuron (330-54-1)" },
{ group: "Pesticides - CUP", label: "fenitrothion (122-14-5)", value: "fenitrothion (122-14-5)" },
{ group: "Pesticides - CUP", label: "fenoxaprop ethyl (66441-23-4)", value: "fenoxaprop ethyl (66441-23-4)" },
{ group: "Pesticides - CUP", label: "fenpropimorph (67564-91-4)", value: "fenpropimorph (67564-91-4)" },
{ group: "Pesticides - CUP", label: "florasulam (145701-23-1)", value: "florasulam (145701-23-1)" },
{ group: "Pesticides - CUP", label: "fluroxypyr (69377-81-7)", value: "fluroxypyr (69377-81-7)" },
{ group: "Pesticides - CUP", label: "fonofos (944-22-9)", value: "fonofos (944-22-9)" },
{ group: "Pesticides - CUP", label: "chlorothalonil (1897-45-6)", value: "chlorothalonil (1897-45-6)" },
{ group: "Pesticides - CUP", label: "chlorpyrifos (2921-88-2)", value: "chlorpyrifos (2921-88-2)" },
{ group: "Pesticides - CUP", label: "chlorpyrifos oxon (5598-15-2)", value: "chlorpyrifos oxon (5598-15-2)" },
{ group: "Pesticides - CUP", label: "chlorsulfuron (64902-72-3)", value: "chlorsulfuron (64902-72-3)" },
{ group: "Pesticides - CUP", label: "chlortoluron (15545-48-9)", value: "chlortoluron (15545-48-9)" },
{ group: "Pesticides - CUP", label: "isoproturon (34123-59-6)", value: "isoproturon (34123-59-6)" },
{ group: "Pesticides - CUP", label: "malathion (121-75-5)", value: "malathion (121-75-5)" },
{ group: "Pesticides - CUP", label: "metamitron (41394-05-2)", value: "metamitron (41394-05-2)" },
{ group: "Pesticides - CUP", label: "metazachlor (67129-08-2)", value: "metazachlor (67129-08-2)" },
{ group: "Pesticides - CUP", label: "metholachlor (51218-45-2)", value: "metholachlor (51218-45-2)" },
{ group: "Pesticides - CUP", label: "methyl parathion (298-00-0)", value: "methyl parathion (298-00-0)" },
{ group: "Pesticides - CUP", label: "metribuzin (21087-64-9)", value: "metribuzin (21087-64-9)" },
{ group: "Pesticides - CUP", label: "pendimethalin (40487-42-1)", value: "pendimethalin (40487-42-1)" },
{ group: "Pesticides - CUP", label: "phosmet (732-11-6)", value: "phosmet (732-11-6)" },
{ group: "Pesticides - CUP", label: "pirimicarb (23103-98-2)", value: "pirimicarb (23103-98-2)" },
{ group: "Pesticides - CUP", label: "prochloraz (67747-09-5)", value: "prochloraz (67747-09-5)" },
{ group: "Pesticides - CUP", label: "propiconazole (60207-90-1)", value: "propiconazole (60207-90-1)" },
{ group: "Pesticides - CUP", label: "pyrazon (1698-60-8)", value: "pyrazon (1698-60-8)" },
{ group: "Pesticides - CUP", label: "simazine (122-34-9)", value: "simazine (122-34-9)" },
{ group: "Pesticides - CUP", label: "tebuconazole (107534-96-3)", value: "tebuconazole (107534-96-3)" },
{ group: "Pesticides - CUP", label: "temefos (3383-96-8)", value: "temefos (3383-96-8)" },
{ group: "Pesticides - CUP", label: "terbufos (13071-79-9)", value: "terbufos (13071-79-9)" },
{ group: "Pesticides - CUP", label: "terbuthylazine (5915-41-3)", value: "terbuthylazine (5915-41-3)" },
{ group: "Pesticides - CUP", label: "tribenuron-metyl (101200-48-0)", value: "tribenuron-metyl (101200-48-0)" },
{ group: "Pesticides - CUP", label: "trifluralin (1582-09-8)", value: "trifluralin (1582-09-8)" },




    ]
}
