export const parameter = {
    code: 'pcbs',
    label : 'PCBs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PCBs", label: "PCB 28 (7012-37-5)", value: "PCB 28 (7012-37-5)" },
{ group: "PCBs", label: "PCB 52 (35693-99-3)", value: "PCB 52 (35693-99-3)" },
{ group: "PCBs", label: "PCB 101 (37680-73-2)", value: "PCB 101 (37680-73-2)" },
{ group: "PCBs", label: "PCB 138 (35065-28-2)", value: "PCB 138 (35065-28-2)" },
{ group: "PCBs", label: "PCB 153 (35065-27-1)", value: "PCB 153 (35065-27-1)" },
{ group: "PCBs", label: "PCB 180 (35065-29-3)", value: "PCB 180 (35065-29-3)" },

    ]
}
