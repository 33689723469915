<template>
  <tr
      :class="[tableSettings.selectable ? 'has-cursor' : '', isSelected(item) ? 'selected' : '']"
      @dblclick="tableSettings.selectable ? $emit('select-item', item) : false"
      @mouseover="showActions = true"
      @mouseleave="showActions = false"
      @contextmenu="showContextRowMenu"
  >
    <!--expand icon-->
    <td class="pa-3" v-if="tableSettings.expandable">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              x-small
              v-bind="attrs"
              v-on="on"
              @click.stop="$emit('expand', item, !isExpanded(item))"
          >
            <v-icon size="26" v-if="!isExpanded(item)">mdi-menu-right</v-icon>
            <v-icon size="26" v-else>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <span>{{ !isExpanded(item) ? $vuetify.lang.t(`$vuetify.dialog.${item['@type']}.expand`) : $vuetify.lang.t(`$vuetify.dialog.${item['@type']}.collapse`) }}</span>
      </v-tooltip>
    </td>
    <!--checkbox-->
    <td class="pa-3" v-if="tableSettings.selectable">
      <v-checkbox
          color="primary"
          :ripple="false"
          :value="isSelected(item)"
          hide-details
          class="ma-0 pa-0"
          @click="$emit('select-item', item)"
      ></v-checkbox>
    </td>
    <!--item-->
    <td
        v-for="(header, index) in headers"
        :key="header.value"
        class="pa-3 text-no-wrap"
        :style="index === headers.length - 1 ? { position: 'relative' } : {}"
    >
      <router-link v-if="header.link" :to="{ path: header.link + '/' + item.id }">{{ getValue (item, header) }}</router-link>
      <v-icon v-else-if="header.type === 'boolean'" :color="item[header.value] ? 'success' : 'error'">{{ item[header.value] ? 'mdi-check' : item[header.value] === null ? '' : 'mdi-close' }}</v-icon>
      <span v-else>{{ getValue (item, header) }}</span>
      <v-slide-x-reverse-transition v-if="index === headers.length - 1 && tableSettings.actions && tableSettings.actions.length && tableSettings.actionsAs && tableSettings.actionsAs.includes('tableCell')">
          <v-container
              fluid
              v-show="showActions"
              :class="['actions_container fill-height pa-2 align-center', showActions ? 'd-flex' : '', $vuetify.theme.dark ? 'dark' : 'light']"
          >
            <v-row no-gutters>
              <v-col v-for="(action, index) in tableSettings.actions" :key="action.name">
                <v-tooltip bottom v-if="action.isVisible">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        min-width="auto"
                        height="auto"
                        tile
                        :color="action.color"
                        :disabled="actionIsDisable(action, item)"
                        elevation="0"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="$emit(action.action)"
                        :class="['pa-1', index < tableSettings.actions.length - 1 ? 'mr-2' : '']"
                    >
                      <v-icon small>{{ action.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.lang.t(`$vuetify.itemAction.${action.name}`) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
      </v-slide-x-reverse-transition>
    </td>

    <v-menu
        v-if="tableSettings.actions && tableSettings.actions.length && tableSettings.actionsAs && tableSettings.actionsAs.includes('contextMenu')"
        v-model="showContextMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        close-on-click
    >
      <v-list>
        <v-list-item
            v-for="(action, index) in tableSettings.actions"
            :key="index"
            :disabled="action.isDisable"
            @click="$emit(action.action)"
        >
          <!--          :exact="child.exact"-->
          <!--          :disabled="child.disabled"-->
          <v-list-item-icon v-if="action.icon" class="mr-2">
            <v-icon>{{ action.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-weight-bold caption">{{ $vuetify.lang.t(`$vuetify.itemAction.${action.name}`) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </tr>
</template>

<script>
export default {
  name: 'TableBodyRow',
  props: ['item', 'headers', 'tableSettings', 'tableOptions', 'isSelected',  'isExpanded'],
  data: () => {
    return {
      showActions: false,
      showContextMenu: false,
      x: 0,
      y: 0
    }
  },
  methods: {
    getValue (item, header) {
      switch (header.type) {
        case 'datetime':
          return item[header.value] ? new Date(item[header.value]).toLocaleString('cs') : ''
        case 'date':
          return item[header.value] ? new Intl.DateTimeFormat('cs').format(new Date(item[header.value])) : ''
        default:
          return item[header.value] !== '[]' ? item[header.value] : ''
      }
    },
    showContextRowMenu (e) {
      if (this.tableSettings.actions && this.tableSettings.actions.length && this.tableSettings.actionsAs && this.tableSettings.actionsAs.includes('contextMenu')) {
        // console.log(this.item.actions)
        e.preventDefault()
        this.showContextMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showContextMenu = true
        })
      }
    },
    actionIsDisable(action, item) {
      if (Object.hasOwn(action, 'isDisable')) {
        return action.isDisable
      }
      return !(this.$store.state.authModule.user.roles.includes('ADMIN') || this.$store.state.authModule.user.roles.includes('OABO_MANAGER') || item.evaluator === '/api/users/' + this.$store.state.authModule.user.id)
    }
  }
}
</script>

<style scoped lang="scss">
  .has-cursor {
    cursor: pointer;
  }

  .theme--light {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
    .expanded {
      td {
        border-bottom: none !important;
        &:first-child {
          border-left: thin solid rgba(0, 0, 0, 0.12);
        }
        &:last-child {
          border-right: thin solid rgba(0, 0, 0, 0.12);
        }
      }
    }
    .selected {
      background-color: var(--v-grey-lighten3);
    }
    .edited {
      background-color: var(--v-primary-lighten3);
    }
    .actions_container{
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      box-shadow: -3px 0px 5px -1px rgba(0, 0, 0, 0.25);
      background-color: rgba(#ffffff, 0.9);
    }
  }
  .theme--dark {
    td {
      border-bottom: thin solid rgba(255, 255, 255, 0.12);
    }
    .expanded {
      td {
        border-bottom: none !important;
        &:first-child {
          border-left: thin solid rgba(255, 255, 255, 0.12);
        }
        &:last-child {
          border-right: thin solid rgba(255, 255, 255, 0.12);
        }
      }
    }
    .selected {
      background-color: var(--v-grey-darken4);
    }
    .edited {
      background-color: var(--v-primary-darken4);
    }
    .actions_container{
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      box-shadow: -3px 0px 5px -1px rgba(0, 0, 0, 0.25);
      background-color: rgba(#1E1E1E, 0.9);
    }
  }


</style>
