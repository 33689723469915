export const parameter = {
    code: 'pesticides-others',
    label : 'Pesticides - others',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        
{ group: "Pesticides - other", label: "captan (133-06-2)", value: "captan (133-06-2)" },
{ group: "Pesticides - other", label: "captan TPHI ()", value: "captan TPHI ()" },
{ group: "Pesticides - other", label: "dichloran (99-30-9)", value: "dichloran (99-30-9)" },
{ group: "Pesticides - other", label: "folpet (133-07-3)", value: "folpet (133-07-3)" },
{ group: "Pesticides - other", label: "glyphosate (1071-83-6)", value: "glyphosate (1071-83-6)" },
{ group: "Pesticides - other", label: "chlorpropham (101-21-3)", value: "chlorpropham (101-21-3)" },
{ group: "Pesticides - other", label: "chlorpyrifos-methyl (5598-13-0)", value: "chlorpyrifos-methyl (5598-13-0)" },


    ]
}
