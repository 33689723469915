export const parameter = {
    code: 'pfas',
    label : 'PFAS',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PFAS", label: "EtFOSA (4151-50-2)", value: "EtFOSA (4151-50-2)" },
{ group: "PFAS", label: "EtFOSE (1691-99-2)", value: "EtFOSE (1691-99-2)" },
{ group: "PFAS", label: "FDET (865-86-1)", value: "FDET (865-86-1)" },
{ group: "PFAS", label: "FHET (647-42-7)", value: "FHET (647-42-7)" },
{ group: "PFAS", label: "FOET (678-39-7)", value: "FOET (678-39-7)" },
{ group: "PFAS", label: "FOSA (754-91-6 )", value: "FOSA (754-91-6 )" },
{ group: "PFAS", label: "MeFOSA (31506-32-8)", value: "MeFOSA (31506-32-8)" },
{ group: "PFAS", label: "MeFOSE (24448-09-7)", value: "MeFOSE (24448-09-7)" },
{ group: "PFAS", label: "PFBA (375-22-4)", value: "PFBA (375-22-4)" },
{ group: "PFAS", label: "PFBS (375-73-5)", value: "PFBS (375-73-5)" },
{ group: "PFAS", label: "PFDA (335-76-2)", value: "PFDA (335-76-2)" },
{ group: "PFAS", label: "PFDoDA (307-55-1)", value: "PFDoDA (307-55-1)" },
{ group: "PFAS", label: "PFDS (335-77-3)", value: "PFDS (335-77-3)" },
{ group: "PFAS", label: "PFHpA (375-85-9)", value: "PFHpA (375-85-9)" },
{ group: "PFAS", label: "PFHpS (375-92-8)", value: "PFHpS (375-92-8)" },
{ group: "PFAS", label: "PFHxA (307-24-4)", value: "PFHxA (307-24-4)" },
{ group: "PFAS", label: "PFHxS (355-46-4)", value: "PFHxS (355-46-4)" },
{ group: "PFAS", label: "PFNA (375-95-1)", value: "PFNA (375-95-1)" },
{ group: "PFAS", label: "PFNS (68259-12-1)", value: "PFNS (68259-12-1)" },
{ group: "PFAS", label: "PFOA (335-67-1)", value: "PFOA (335-67-1)" },
{ group: "PFAS", label: "PFOS (1763-23-1)", value: "PFOS (1763-23-1)" },
{ group: "PFAS", label: "PFPeA (2706-90-3)", value: "PFPeA (2706-90-3)" },
{ group: "PFAS", label: "PFPeS (2706-91-4)", value: "PFPeS (2706-91-4)" },
{ group: "PFAS", label: "PFTeDA (376-06-7)", value: "PFTeDA (376-06-7)" },
{ group: "PFAS", label: "PFTrDA (72629-94-8)", value: "PFTrDA (72629-94-8)" },
{ group: "PFAS", label: "PFUnDA (4234-23-5)", value: "PFUnDA (4234-23-5)" },

    ]
}
