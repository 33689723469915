import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'
import enExt from '@/translations/enExt'
import csExt from '@/translations/csExt'
import colors from 'vuetify/lib/util/colors'
import RcxMuniLogo from '@/components/customIcons/RcxMuniLogo'
import VueLogo from '@/components/customIcons/VueLogo.vue'
import VuetifyLogo from '@/components/customIcons/VuetifyLogo.vue'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: colors.blue.base,
                // primary: '#007BFF',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                celspac: '#58A0B6',
            },
            dark: {
                primary: colors.blueGrey.base,
                celspac: '#58A0B6'
            }
        }
    },
    icons: {
        iconfont: 'mdi',
        values: {
            rcxMuniLogo: {
                component: RcxMuniLogo
            },
            vueLogo: {
                component: VueLogo
            },
            vuetifyLogo: {
                component: VuetifyLogo
            }
        },
    },
    lang: {
        locales: { enExt, csExt },
        current: 'enExt'
    }
});
