export const parameter = {
    code: 'pahs',
    label : 'PAHs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PAHs", label: "acenaphtene (83-32-9)", value: "acenaphtene (83-32-9)" },
{ group: "PAHs", label: "acenaphtylene (208-96-8)", value: "acenaphtylene (208-96-8)" },
{ group: "PAHs", label: "anthanthrene (191-26-4)", value: "anthanthrene (191-26-4)" },
{ group: "PAHs", label: "anthracene (120-12-7)", value: "anthracene (120-12-7)" },
{ group: "PAHs", label: "benzo(a)anthracene (56-55-3)", value: "benzo(a)anthracene (56-55-3)" },
{ group: "PAHs", label: "benzo(a)pyrene (50-32-8)", value: "benzo(a)pyrene (50-32-8)" },
{ group: "PAHs", label: "benzo(b)fluoranthene (205-99-2)", value: "benzo(b)fluoranthene (205-99-2)" },
{ group: "PAHs", label: "benzo(b)fluorene (243-17-4)", value: "benzo(b)fluorene (243-17-4)" },
{ group: "PAHs", label: "benzo(e)pyrene (192-97-2)", value: "benzo(e)pyrene (192-97-2)" },
{ group: "PAHs", label: "benzo(ghi)fluoranthene (203-12-3)", value: "benzo(ghi)fluoranthene (203-12-3)" },
{ group: "PAHs", label: "benzo(ghi)perylene (191-24-2)", value: "benzo(ghi)perylene (191-24-2)" },
{ group: "PAHs", label: "benzo(j)fluoranthene (205-82-3)", value: "benzo(j)fluoranthene (205-82-3)" },
{ group: "PAHs", label: "benzo(k)fluoranthene (207-08-9)", value: "benzo(k)fluoranthene (207-08-9)" },
{ group: "PAHs", label: "benzo-naphto-thiophene (239-35-0)", value: "benzo-naphto-thiophene (239-35-0)" },
{ group: "PAHs", label: "biphenyl (92-52-4)", value: "biphenyl (92-52-4)" },
{ group: "PAHs", label: "coronene (191-07-1)", value: "coronene (191-07-1)" },
{ group: "PAHs", label: "cyclopenta(cd)pyrene (27208-37-3)", value: "cyclopenta(cd)pyrene (27208-37-3)" },
{ group: "PAHs", label: "dibenz(ac)anthracene (215-58-7)", value: "dibenz(ac)anthracene (215-58-7)" },
{ group: "PAHs", label: "dibenz(ah)anthracene (53-70-3)", value: "dibenz(ah)anthracene (53-70-3)" },
{ group: "PAHs", label: "fluoranthene (206-44-0)", value: "fluoranthene (206-44-0)" },
{ group: "PAHs", label: "fluorene (86-73-7)", value: "fluorene (86-73-7)" },
{ group: "PAHs", label: "chrysene (218-01-9)", value: "chrysene (218-01-9)" },
{ group: "PAHs", label: "indeno(123cd)pyrene (193-39-5)", value: "indeno(123cd)pyrene (193-39-5)" },
{ group: "PAHs", label: "naphthalene (91-20-3)", value: "naphthalene (91-20-3)" },
{ group: "PAHs", label: "perylene (198-55-0)", value: "perylene (198-55-0)" },
{ group: "PAHs", label: "phenantrene (85-01-8)", value: "phenantrene (85-01-8)" },
{ group: "PAHs", label: "pyrene (129-00-0)", value: "pyrene (129-00-0)" },
{ group: "PAHs", label: "retene (483-65-8)", value: "retene (483-65-8)" },
{ group: "PAHs", label: "triphenylene (217-59-4)", value: "triphenylene (217-59-4)" },


    ]
}
