export const parameter = {
    code: 'pbbs',
    label : 'PBBs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PBBs", label: "moBB 3  (92-66-0)", value: "moBB 3  (92-66-0)" },
{ group: "PBBs", label: "diBB 15  (92-86-4)", value: "diBB 15  (92-86-4)" },
{ group: "PBBs", label: "triBB 18  (NA)", value: "triBB 18  (NA)" },
{ group: "PBBs", label: "teBB 52  (NA)", value: "teBB 52  (NA)" },
{ group: "PBBs", label: "peBB 101  (NA)", value: "peBB 101  (NA)" },
{ group: "PBBs", label: "hxBB 153  (59080-40-9)", value: "hxBB 153  (59080-40-9)" },
{ group: "PBBs", label: "hpBB 180  (67733-52-2)", value: "hpBB 180  (67733-52-2)" },
{ group: "PBBs", label: "ocBB 194  (67889-00-3)", value: "ocBB 194  (67889-00-3)" },
{ group: "PBBs", label: "noBB 206  (69278-62-2)", value: "noBB 206  (69278-62-2)" },
{ group: "PBBs", label: "deBB 209  (NA)", value: "deBB 209  (NA)" },


    ]
}
