import en from 'vuetify/lib/locale/en'

export default {
  ...en,
  language: {
    tooltip: 'Change language',
    enExt: 'English',
    csExt: 'Czech'
  },
  logout: {
    tooltip: 'Logout'
  },
  noDepartments: 'No departments defined.',
  noServices: 'No services defined.',
  noForm: 'No form defined.',
  noUser: 'No user signed in.',
  dashboard: 'Dashboard',
  pageNotFound: '404: Page not found',
  sendFormSuccess: 'Form has been submitted successfully.',
  formNotValidStrong: 'Form is not valid.',
  formNotValid: 'Please check all form fields again.',
  sendRequest: 'Submit form',
  updateRequest: 'Update request',
  title: 'RECETOX e-surveys',
  loading: 'Loading data...',
  tableHeaders: {
    id: 'iD',
    emailAddress: 'E-mail',
    fullName: 'Full name',
    surveyFormState: 'Form state',
    surveyFormType: 'Form type',
    
    monitoringNetworkTitle: 'Monitoring network',
    monitoringNetworkAcronym: 'Monitoring network - acronym',
    monitoringNetworkDataOwner: 'Data owner',
    monitoringNetworkDataSourceType: 'Data source type',
    monitoringNetworkWebPage: 'Web page',
    
    createdAt: 'Created at',
    updatedAt: 'Updated at',
  },
  itemAction: {
    edit: 'Edit item',
    update: 'Update item',
    remove: 'Remove item',
    cancel: 'Cancel item',
    detail: 'View item',
    delete: 'Delete item',
    download: 'Download item',
  },
}
