export const parameter = {
    code: 'pcdds_fs',
    label : 'PCDDs/Fs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PCDDs/Fs", label: "2,3,7,8-TCDD (1746-01-6)", value: "2,3,7,8-TCDD (1746-01-6)" },
{ group: "PCDDs/Fs", label: "1,2,3,7,8-PeCDD (40321-76-4)", value: "1,2,3,7,8-PeCDD (40321-76-4)" },
{ group: "PCDDs/Fs", label: "1,2,3,4,7,8-HxCDD (39227-28-6)", value: "1,2,3,4,7,8-HxCDD (39227-28-6)" },
{ group: "PCDDs/Fs", label: "1,2,3,6,7,8-HxCDD (57653-85-7)", value: "1,2,3,6,7,8-HxCDD (57653-85-7)" },
{ group: "PCDDs/Fs", label: "1,2,3,7,8,9-HxCDD (19408-74-3)", value: "1,2,3,7,8,9-HxCDD (19408-74-3)" },
{ group: "PCDDs/Fs", label: "1,2,3,4,6,7,8-HpCDD (35822-46-9)", value: "1,2,3,4,6,7,8-HpCDD (35822-46-9)" },
{ group: "PCDDs/Fs", label: "OCDD (3268-87-9)", value: "OCDD (3268-87-9)" },
{ group: "PCDDs/Fs", label: "2,3,7,8-TCDF (51207-31-9)", value: "2,3,7,8-TCDF (51207-31-9)" },
{ group: "PCDDs/Fs", label: "1,2,3,7,8-PeCDF (57117-41-6)", value: "1,2,3,7,8-PeCDF (57117-41-6)" },
{ group: "PCDDs/Fs", label: "2,3,4,7,8-PeCDF (57117-31-4)", value: "2,3,4,7,8-PeCDF (57117-31-4)" },
{ group: "PCDDs/Fs", label: "1,2,3,4,7,8-HxCDF (70648-26-9)", value: "1,2,3,4,7,8-HxCDF (70648-26-9)" },
{ group: "PCDDs/Fs", label: "1,2,3,6,7,8-HxCDF (57117-44-9)", value: "1,2,3,6,7,8-HxCDF (57117-44-9)" },
{ group: "PCDDs/Fs", label: "2,3,4,6,7,8-HxCDF (60851-34-5)", value: "2,3,4,6,7,8-HxCDF (60851-34-5)" },
{ group: "PCDDs/Fs", label: "1,2,3,7,8,9-HxCDF (72918-21-9)", value: "1,2,3,7,8,9-HxCDF (72918-21-9)" },
{ group: "PCDDs/Fs", label: "1,2,3,4,6,7,8-HpCDF (67562-39-4)", value: "1,2,3,4,6,7,8-HpCDF (67562-39-4)" },
{ group: "PCDDs/Fs", label: "1,2,3,4,7,8,9-HpCDF (55673-89-7)", value: "1,2,3,4,7,8,9-HpCDF (55673-89-7)" },
{ group: "PCDDs/Fs", label: "OCDF (39001-02-0)", value: "OCDF (39001-02-0)" },


    ]
}
