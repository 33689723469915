<template>
  <v-container :fluid="fluidProp">
    <v-row justify="center">
      <v-col cols="auto" class="display-1 font-weight-bold primary--text" sm="12" md="10" lg="8" xl="6">
        <h3>Detail of survey id {{ $route.params.id }}</h3>
      </v-col>
    </v-row>
<!--{{ fullForm }}-->
    <v-row justify="center">
      <v-col sm="12" md="10" lg="8" xl="6">
        <form-wrapper
            ref="form"
            class="pa-0"
            :form-config="formConfig"
            :request-form-data="requestFormDataComputed"
            :form-disable="true"
            @show-snack="$emit('show-snack', $event)"
        ></form-wrapper>
      </v-col>
    </v-row>
<!--    <v-row v-else>-->
<!--      <v-col>-->
<!--        <v-alert type="error">Sorry, none of your forms contain "{{ department }}" and "{{ service }}"</v-alert>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </v-container></template>

<script>
import FormWrapper from '@recetox/open-access-forms/src/components/form/FormWrapper.vue'
import * as forms from '@/components/form/config/formConfig'

export default {
  name: 'SurveyDetailView',
  props: ['fluidProp', 'department', 'service', 'fullForm'],
  data () {
    return {
      formConfig: []
    }
  },
  components: {
    FormWrapper
  },
  computed: {
    requestFormDataComputed () {
      if (this.fullForm) return this.fullForm
      return null
    }
  },
  mounted() {
    this.getFormConfig()
  },
  methods: {
    getFormConfig () {
      if (this.department && this.service) this.formConfig = forms[`${this.department}_${this.service}_Config`]
    }
  }
}
</script>

<style scoped>

</style>
