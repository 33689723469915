export const parameter = {
    code: 'pbdes',
    label : 'PBDEs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PBDEs", label: "PBDE 17 (147217-75-2)", value: "PBDE 17 (147217-75-2)" },
{ group: "PBDEs", label: "PBDE 28 (41318-75-6)", value: "PBDE 28 (41318-75-6)" },
{ group: "PBDEs", label: "PBDE 47 (5436-43-1)", value: "PBDE 47 (5436-43-1)" },
{ group: "PBDEs", label: "PBDE 49 (243982-82-3)", value: "PBDE 49 (243982-82-3)" },
{ group: "PBDEs", label: "PBDE 66 (189084-61-5)", value: "PBDE 66 (189084-61-5)" },
{ group: "PBDEs", label: "PBDE 77 (40088-47-9)", value: "PBDE 77 (40088-47-9)" },
{ group: "PBDEs", label: "PBDE 85 (182346-21-0)", value: "PBDE 85 (182346-21-0)" },
{ group: "PBDEs", label: "PBDE 99 (60348-60-9)", value: "PBDE 99 (60348-60-9)" },
{ group: "PBDEs", label: "PBDE 100 (189084-64-8)", value: "PBDE 100 (189084-64-8)" },
{ group: "PBDEs", label: "PBDE 118 ()", value: "PBDE 118 ()" },
{ group: "PBDEs", label: "PBDE 126 (366791-32-4)", value: "PBDE 126 (366791-32-4)" },
{ group: "PBDEs", label: "PBDE 138 (182677-30-1)", value: "PBDE 138 (182677-30-1)" },
{ group: "PBDEs", label: "PBDE 139 ()", value: "PBDE 139 ()" },
{ group: "PBDEs", label: "PBDE 153 (68631-49-2)", value: "PBDE 153 (68631-49-2)" },
{ group: "PBDEs", label: "PBDE 154 (207122-15-4)", value: "PBDE 154 (207122-15-4)" },
{ group: "PBDEs", label: "PBDE 181 (189084-67-1)", value: "PBDE 181 (189084-67-1)" },
{ group: "PBDEs", label: "PBDE 183 (207122-16-5)", value: "PBDE 183 (207122-16-5)" },
{ group: "PBDEs", label: "PBDE 196 (446255-38-5)", value: "PBDE 196 (446255-38-5)" },
{ group: "PBDEs", label: "PBDE 197 ()", value: "PBDE 197 ()" },
{ group: "PBDEs", label: "PBDE 203 ()", value: "PBDE 203 ()" },
{ group: "PBDEs", label: "PBDE 207 ()", value: "PBDE 207 ()" },
{ group: "PBDEs", label: "PBDE 209 (1163-19-5)", value: "PBDE 209 (1163-19-5)" },


    ]
}
