import axios from 'axios'
// import store from '@/store/index'
import { eventBus } from '@/store/eventBus'

axios.interceptors.request.use(
  config => {
    config.baseURL = process.env.VUE_APP_BASE_API_URL
    config.headers = {
      ...config.headers,
      // 'oaf-backoffice-secret': process.env.VUE_APP_OAF_BACKOFFICE_SECRET
    }
    // if (localStorage.getItem('oaf-backOffice-appToken')) {
    //     config.headers['X-AUTH-TOKEN'] = localStorage.getItem('oaf-backOffice-appToken')
    //     // config.headers.Authorization = 'Bearer ' + localStorage.getItem('oaf-backOffice-appToken')
    // }
    // if (localStorage.getItem('actualStudyGroup')) {
    //   config.headers['clade-group'] = JSON.parse(localStorage.getItem('actualStudyGroup')).id
    // }
    // if (config.data && Object.keys(config.data).includes('grant_type')) {
    //   delete config.headers['clade-token']
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use((response) => {
  // console.log('interceptor response', response)
  return response
}, async (error) => {
    console.log('error:', error)
    console.log('error.response:', error.response)
    if (error.response.status === 401) {
        eventBus.$emit('show-snack', { text: '<strong>' + error.response.status + ': ' + error.response.statusText + ' - </strong>' + error.response.data['hydra:title'] + ', ' + error.response.data['hydra:description'], color: 'error', ico: 'mdi-thumb-down' })
        eventBus.$emit('logout')
        console.log('now logout:', error.response)
    }
  // if (error.response.status === 401 && error.response.data.statusMessage === 'Expired JWT Token') {
  //   // console.log('now refresh tokens:', error.response)
  //   await store.dispatch('authModule/renewToken')
  //   return axios(error.config)
  // } else if (error.response.status === 401) {
  //   // logout
  //   this.$emit('show-snack', { text: '<strong>' + error.response.status + ': ' + error.response.statusText + ' - </strong>' + error.response.data['hydra:title'] + ', ' + error.response.data['hydra:description'], color: 'error', ico: 'mdi-thumb-down' })
  //   eventBus.$emit('logout')
  //   // console.log('now logout:', error.response)
  // }
  // // else {
  // //   console.log('other variant:', error.response)
  // // }

  return Promise.reject(error)
})

export default axios
