export const parameter = {
    code: 'bisphenols',
    label : 'Bisphenols',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Bisphenols", label: "BADGE (2,2’-[(1- methylethylidene)bis(4,1 phenyleneoxymethylene)]bisoxirane ) (1675-54-3)", value: "BADGE (2,2’-[(1- methylethylidene)bis(4,1 phenyleneoxymethylene)]bisoxirane ) (1675-54-3)" },
{ group: "Bisphenols", label: "BPA (2,2-Bis(4-hydroxyphenyl)propane) (80-05-7)", value: "BPA (2,2-Bis(4-hydroxyphenyl)propane) (80-05-7)" },
{ group: "Bisphenols", label: "BPAF (4,4'-[2,2,2-trifluoro-1- (trifluoromethyl)ethylide ne]diphenol ) (1478-61-1)", value: "BPAF (4,4'-[2,2,2-trifluoro-1- (trifluoromethyl)ethylide ne]diphenol ) (1478-61-1)" },
{ group: "Bisphenols", label: "BPAP (1,1-bis(4-hydroxyphenyl)-1-phenylethane) (1571-75-1)", value: "BPAP (1,1-bis(4-hydroxyphenyl)-1-phenylethane) (1571-75-1)" },
{ group: "Bisphenols", label: "BPB (4,4'-(1-methylpropylidene)bisphenol; bisphenol B) (77-40-7)", value: "BPB (4,4'-(1-methylpropylidene)bisphenol; bisphenol B) (77-40-7)" },
{ group: "Bisphenols", label: "BPE (4,4'-Ethylidenebisphenol) (2081-08-5)", value: "BPE (4,4'-Ethylidenebisphenol) (2081-08-5)" },
{ group: "Bisphenols", label: "BPF (4,4'-methylenediphenol) (620-92-8)", value: "BPF (4,4'-methylenediphenol) (620-92-8)" },
{ group: "Bisphenols", label: "BPP (4,4'-(1,4-Phenylenediisopropylidene)bisphenol) (2167-51-3)", value: "BPP (4,4'-(1,4-Phenylenediisopropylidene)bisphenol) (2167-51-3)" },
{ group: "Bisphenols", label: "BPPH (2,2-Bis(2-hydroxy-5- biphenylyl)propane ) (24038-68-4)", value: "BPPH (2,2-Bis(2-hydroxy-5- biphenylyl)propane ) (24038-68-4)" },
{ group: "Bisphenols", label: "BPS (4,4'- sulphonyldiphenol) (80-09-1)", value: "BPS (4,4'- sulphonyldiphenol) (80-09-1)" },
{ group: "Bisphenols", label: "BPS-MAE (4-(4-Allyloxy- benzenesulfonyl)- phenol) (97042-18-7)", value: "BPS-MAE (4-(4-Allyloxy- benzenesulfonyl)- phenol) (97042-18-7)" },
{ group: "Bisphenols", label: "BPS-MPE (p-[[p- benzyloxyphenyl]s ulphonyl]phenol) (63134-33-8)", value: "BPS-MPE (p-[[p- benzyloxyphenyl]s ulphonyl]phenol) (63134-33-8)" },
{ group: "Bisphenols", label: "BPZ  (4,4'-cyclohexylidenebisphenol) (843-55-0)", value: "BPZ  (4,4'-cyclohexylidenebisphenol) (843-55-0)" },
{ group: "Bisphenols", label: "Pergafast 201  (232938-43-1)", value: "Pergafast 201  (232938-43-1)" },


    ]
}
