export const parameter = {
    code: 'pcbs_dl',
    label : 'PCBs-dl',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PCBs-dl", label: "PCB 77 (32598-13-3)", value: "PCB 77 (32598-13-3)" },
{ group: "PCBs-dl", label: "PCB 81 (70362-50-4)", value: "PCB 81 (70362-50-4)" },
{ group: "PCBs-dl", label: "PCB 105 (32598-14-4)", value: "PCB 105 (32598-14-4)" },
{ group: "PCBs-dl", label: "PCB 114 (74472-37-0)", value: "PCB 114 (74472-37-0)" },
{ group: "PCBs-dl", label: "PCB 118 (31508-00-6)", value: "PCB 118 (31508-00-6)" },
{ group: "PCBs-dl", label: "PCB 123 (65510-44-3)", value: "PCB 123 (65510-44-3)" },
{ group: "PCBs-dl", label: "PCB 126 (57465-28-8)", value: "PCB 126 (57465-28-8)" },
{ group: "PCBs-dl", label: "PCB 156 (38380-08-4)", value: "PCB 156 (38380-08-4)" },
{ group: "PCBs-dl", label: "PCB 157 (69782-90-7)", value: "PCB 157 (69782-90-7)" },
{ group: "PCBs-dl", label: "PCB 167 (52663-72-6)", value: "PCB 167 (52663-72-6)" },
{ group: "PCBs-dl", label: "PCB 169 (32774-16-6)", value: "PCB 169 (32774-16-6)" },
{ group: "PCBs-dl", label: "PCB 189 (39635-31-9)", value: "PCB 189 (39635-31-9)" },

    ]
}
