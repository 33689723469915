export const parameter = {
    code: 'Musks',
    label : 'Musks',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Musks", label: "Cashmeran DPMI (33704-61-9)", value: "Cashmeran DPMI (33704-61-9)" },
{ group: "Musks", label: "Celestolide ADBI (13171-00-1)", value: "Celestolide ADBI (13171-00-1)" },
{ group: "Musks", label: "Galaxolide HHCB (1222-05-5)", value: "Galaxolide HHCB (1222-05-5)" },
{ group: "Musks", label: "Phantolide AHM (15323-35-0)", value: "Phantolide AHM (15323-35-0)" },
{ group: "Musks", label: "Tonalide AHTN (21145-77-7)", value: "Tonalide AHTN (21145-77-7)" },
{ group: "Musks", label: "Traseolide ATII (68140-48-7)", value: "Traseolide ATII (68140-48-7)" },


    ]
}
