<template functional>
  <svg class="rcx-muni-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 745.9 82.6" height="100%" xml:space="preserve">
<path d="M0,9.2h10v64.2H0V9.2z M10.4,9.2h3.4L20,73.4h-3.4L10.4,9.2z M26.6,9.2H30l-6.2,64.2h-3.4L26.6,9.2z M30.4,9.2h10.1v64.2
	H30.4V9.2z M68.3,9.2v49.5c0,8.6,7.8,15.3,16.1,15.3c8.4,0,16.2-6.7,16.2-15.3V9.2H90.5v49.1c0,3.5-3.1,6-6.1,6s-6.1-2.5-6.1-6V9.2
	H68.3z M141.7,73.4h-10.1V9.2h10.1V73.4z M145.6,9.2l9.5,64.2h-3.5l-9.5-64.2C142.1,9.2,145.6,9.2,145.6,9.2z M165.6,73.4h-10.1V9.2
	h10.1V73.4z M199.1,69.8v3.6h27.4v-3.6h-8.7V12.8h8.7V9.2h-27.4v3.6h8.6v57.1L199.1,69.8L199.1,69.8z M278.9,0h-3.7v82.6h3.7V0z
  M343.8,9.2c8.3,0,15.3,7,15.3,15.3v8.4c0,5.1-2.9,10.3-7.5,13.1l10.6,27.3h-10.9l-9.4-25.2h-4.8v25.2H327V9.2H343.8z M349.1,30.7
	V25c0-4-3.5-6.6-7.3-6.6h-4.7v20.2h4.7C346.5,38.5,349.1,36.5,349.1,30.7 M391.1,73.4h30.6v-9.2h-20.6v-20h19.4V35h-19.4V18.3h20.6
	V9.2h-30.6V73.4z M454,58.6c0,8.6,7.8,15.3,16.1,15.3c8.4,0,16.1-6.7,16.1-15.3v-3.9h-10.1v3.5c0,3.5-3.1,6-6.1,6
	c-2.9,0-6.1-2.5-6.1-6V24.3c0-3.5,3.1-6,6.1-6c2.9,0,6.1,2.5,6.1,6v3.6h10.1v-4c0-8.6-7.8-15.3-16.1-15.3c-8.3,0-16.1,6.7-16.1,15.3
	V58.6 M519.5,73.4h30.6v-9.2h-20.6v-20h19.4V35h-19.4V18.3h20.6V9.2h-30.6V73.4z M593.1,18.3v55.1h10.1V18.3h14.6V9.2h-39.3v9.2
	H593.1z M662.4,8.6c8.4,0,16.1,6.6,16.1,15.5v34.3c0,8.9-7.8,15.5-16.1,15.5c-8.3,0-16.1-6.6-16.1-15.5V24.1
	C646.2,15.2,654,8.6,662.4,8.6 M662.4,18.3c-2.9,0-6.1,2.1-6.1,5.9v34.1c0,3.8,3.1,5.9,6.1,5.9c2.9,0,6.1-2.1,6.1-5.9V24.2
	C668.4,20.5,665.3,18.3,662.4,18.3 M721.1,41.1l-13.9,32.3H718l8.5-20.9l8.5,20.9h10.8l-13.8-32.3l13.8-31.9h-10.8l-8.5,20.7
	L718,9.2h-10.8L721.1,41.1z"/>
  <a xlink:href="http://www.recetox.muni.cz" target="_blank">
    <rect x="0" y="0" width="100%" height="100%" fill="transparent"></rect>
  </a>
</svg>
</template>

<script>
export default {}
</script>
<style lang="scss">
.rcx-muni-logo {
 fill: currentColor;
}
</style>
