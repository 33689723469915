import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../views/Dashboard.vue'
import SurveysView from '../views/SurveysView.vue'
import SurveyDetailView from '../views/SurveyDetailView.vue'
import SurveyFormView from '../views/SurveyFormView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/surveys/:department/:service',
    name: 'surveys-view',
    component: SurveysView,
    props: route => ({
      department: route.params.department,
      service: route.params.service,
      fluidProp: true
    })
  },
  {
    path: '/survey-detail/:department/:service/:id',
    name: 'surveys-detail-view',
    component: SurveyDetailView,
    props: route => ({
      department: route.params.department,
      service: route.params.service,
      fullForm: route.params.fullForm,
    })
  },
  {
    path: '/:department/:service',
    name: 'survey-form-view',
    component: SurveyFormView,
    props: route => ({
      department: route.params.department,
      service: route.params.service,
    })
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "page-not-found" */ '../components/PageNotFound.vue'),
    name: 'PageNotFound'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
