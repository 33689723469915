export const parameter = {
    code: 'microcystins',
    label : 'Microcystins',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Microcystins", label: "microcystin-LA / Cyanoginosin-LA (96180-79-9)", value: "microcystin-LA / Cyanoginosin-LA (96180-79-9)" },
{ group: "Microcystins", label: "microcystin-LR (101043-37-2)", value: "microcystin-LR (101043-37-2)" },
{ group: "Microcystins", label: "microcystin-RR (111755-37-4)", value: "microcystin-RR (111755-37-4)" },
{ group: "Microcystins", label: "microcystin-YR (101064-48-6)", value: "microcystin-YR (101064-48-6)" },

    ]
}
