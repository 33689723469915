export const parameter = {
    code: 'hcbd',
    label : 'HCBD',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "HCBD", label: "hexachloro-1,3-butadiene (87-68-3)", value: "hexachloro-1,3-butadiene (87-68-3)" },

    ]
}
