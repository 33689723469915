export const parameter = {
    code: 'pahs_substituted',
    label : 'PAHs - substituted',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        
{ group: "PAHs - substituted", label: "1,3-dinitropyrene (75321-20-9)", value: "1,3-dinitropyrene (75321-20-9)" },
{ group: "PAHs - substituted", label: "1,4-anthraquinone (635-12-1)", value: "1,4-anthraquinone (635-12-1)" },
{ group: "PAHs - substituted", label: "1,4-naphthoquinone (130-15-4)", value: "1,4-naphthoquinone (130-15-4)" },
{ group: "PAHs - substituted", label: "1,6-dinitropyrene (42397-64-8)", value: "1,6-dinitropyrene (42397-64-8)" },
{ group: "PAHs - substituted", label: "1,8-dinitropyrene (42397-65-9)", value: "1,8-dinitropyrene (42397-65-9)" },
{ group: "PAHs - substituted", label: "1-nitronaphthalene (86-57-7)", value: "1-nitronaphthalene (86-57-7)" },
{ group: "PAHs - substituted", label: "1-nitronaphthalene-D7 (80789-77-1)", value: "1-nitronaphthalene-D7 (80789-77-1)" },
{ group: "PAHs - substituted", label: "1-nitropyrene (5522-43-0)", value: "1-nitropyrene (5522-43-0)" },
{ group: "PAHs - substituted", label: "1-nitropyrene-D9 (93487-20-8)", value: "1-nitropyrene-D9 (93487-20-8)" },
{ group: "PAHs - substituted", label: "2-nitrofluoranthene (13177-29-2)", value: "2-nitrofluoranthene (13177-29-2)" },
{ group: "PAHs - substituted", label: "2-nitrofluorene (607-57-8)", value: "2-nitrofluorene (607-57-8)" },
{ group: "PAHs - substituted", label: "2-nitrofluorene-D9 (128008-87-7)", value: "2-nitrofluorene-D9 (128008-87-7)" },
{ group: "PAHs - substituted", label: "2-nitronaphthalene (581-89-5)", value: "2-nitronaphthalene (581-89-5)" },
{ group: "PAHs - substituted", label: "2-nitropyrene (789-07-1)", value: "2-nitropyrene (789-07-1)" },
{ group: "PAHs - substituted", label: "3-nitroacenaphthene (3807-77-0)", value: "3-nitroacenaphthene (3807-77-0)" },
{ group: "PAHs - substituted", label: "3-nitrofluoranthene (892-21-7)", value: "3-nitrofluoranthene (892-21-7)" },
{ group: "PAHs - substituted", label: "3-nitrofluoranthene-D9 (350820-11-0)", value: "3-nitrofluoranthene-D9 (350820-11-0)" },
{ group: "PAHs - substituted", label: "3-nitrophenanthrene (17024-19-0)", value: "3-nitrophenanthrene (17024-19-0)" },
{ group: "PAHs - substituted", label: "5,12-naphthacenequinone (1090-13-7)", value: "5,12-naphthacenequinone (1090-13-7)" },
{ group: "PAHs - substituted", label: "5-nitroacenaphthene (602-87-9)", value: "5-nitroacenaphthene (602-87-9)" },
{ group: "PAHs - substituted", label: "6-nitrobenzoapyrene (63041-90-7)", value: "6-nitrobenzoapyrene (63041-90-7)" },
{ group: "PAHs - substituted", label: "6-nitrobenzoapyrene-D11 (352431-12-0)", value: "6-nitrobenzoapyrene-D11 (352431-12-0)" },
{ group: "PAHs - substituted", label: "6-nitrochrysene (7496-02-8)", value: "6-nitrochrysene (7496-02-8)" },
{ group: "PAHs - substituted", label: "6-nitrochrysene-D11 (203805-92-9)", value: "6-nitrochrysene-D11 (203805-92-9)" },
{ group: "PAHs - substituted", label: "7-nitrobenzoaanthracene (20268-51-3)", value: "7-nitrobenzoaanthracene (20268-51-3)" },
{ group: "PAHs - substituted", label: "9,10-anthraquinone (84-65-1)", value: "9,10-anthraquinone (84-65-1) ("},
{ group: "PAHs - substituted", label: "9,10-phenanthroquinone (84-11-7)", value: "9,10-phenanthroquinone (84-11-7)" },
{ group: "PAHs - substituted", label: "9-fluorenone (486-25-9)", value: "9-fluorenone (486-25-9)" },
{ group: "PAHs - substituted", label: "9-nitroanthracene (602-60-8)", value: "9-nitroanthracene (602-60-8)" },
{ group: "PAHs - substituted", label: "9-nitroanthracene-D9 (220381-38-4)", value: "9-nitroanthracene-D9 (220381-38-4)" },
{ group: "PAHs - substituted", label: "9-nitrophenanthrene (954-46-1)", value: "9-nitrophenanthrene (954-46-1)" },
{ group: "PAHs - substituted", label: "benz(a)anthracene-7,12-dione (2498-66-0)", value: "benz(a)anthracene-7,12-dione (2498-66-0)" },
{ group: "PAHs - substituted", label: "benzanthrone (82-05-3)", value: "benzanthrone (82-05-3)" },
{ group: "PAHs - substituted", label: "benzo-a-fluoren-11-one (479-79-8)", value: "benzo-a-fluoren-11-one (479-79-8)" },
{ group: "PAHs - substituted", label: "benzo-b-fluoren-11-one (3074-03-1)", value: "benzo-b-fluoren-11-one (3074-03-1)" },
{ group: "PAHs - substituted", label: "naphthalene-1-aldehyde (66-77-3)", value: "naphthalene-1-aldehyde (66-77-3)" },


    ]
}
