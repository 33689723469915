<template>
  <v-container :fluid="fluidProp">
    <v-row justify="center">
      <v-col cols="auto" class="display-1 font-weight-bold primary--text" sm="12" md="10" lg="8" xl="6">
        <h3>European Environmental Networks</h3>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col sm="12" md="10" lg="8" xl="6">
        <p>This survey is focused on <strong>environmental monitoring networks relevant to chemical risk assessment</strong> within the European region.</p>
        <p>Examples:
          <ul>
            <li>EMEP - European Monitoring and Evaluation Programme: https://www.emep.int.</li>
          </ul>
        </p>
        <p>
          This survey aims to collect the most comprehensive range of environmental monitoring networks. Fill in as much information as you are capable of filling. If you are unsure or unable to fill in any information, skip it. We will be happy for any contribution, even with basic information on the existence of a particular monitoring network.
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col sm="12" md="10" lg="8" xl="6">
        <h3>We are interested in the following:</h3>
        <p></p>

        <ul class="mb-3">
          <li>Basic information on monitoring network</li>
          <li>The temporal and spatial extent of the monitoring</li>
          <li>Description of the database in which the data is stored</li>
          <li>List of cooperating laboratories</li>
          <li>List of measured chemical substances in environmental matrices</li>
        </ul>

        <p class="mb-0">In case of any questions or ambiguities, contact the administrators of this questionnaire:</p>

        <ul style="list-style-type: none">
          <li><a href="mailto:jana.boruvkova@recetox.muni.cz">jana.boruvkova@recetox.muni.cz</a></li>
          <li><a href="mailto:zdenka.bednarova@recetox.muni.cz">zdenka.bednarova@recetox.muni.cz</a></li>
        </ul>
      </v-col>
    </v-row>

    <v-row v-if="formConfig" justify="center">
      <v-col sm="12" md="10" lg="8" xl="6">
        <form-wrapper ref="form" class="pa-0" :form-config="formConfig" @show-snack="$emit('show-snack', $event)"></form-wrapper>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-alert type="error">Sorry, none of your forms contain "{{ department }}" and "{{ service }}"</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FormWrapper from '@recetox/open-access-forms/src/components/form/FormWrapper.vue'
  import * as forms from '@/components/form/config/formConfig'

  export default {
    name: 'SurveyFormView',
    props: ['fluidProp', 'department', 'service'],
    components: {
      FormWrapper,
    },
    computed: {
      formConfig() {
        if (this.department && this.service) return forms[`${this.department}_${this.service}_Config`];
        return [];
      },
    },
  };
</script>

<style scoped></style>
