export const parameter = {
    code: 'Elements',
    label : 'Elements',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Elements", label: "Aluminium (7429-90-5)", value: "Aluminium (7429-90-5)"},
{ group: "Elements", label: "Antimony ()", value: "Antimony ()"},
{ group: "Elements", label: "Arsenic ()", value: "Arsenic ()"},
{ group: "Elements", label: "Arsenic (III) (22541-54-4)", value: "Arsenic (III) (22541-54-4)"},
{ group: "Elements", label: "Arsenic (V) (17428-41-0)", value: "Arsenic (V) (17428-41-0)"},
{ group: "Elements", label: "Arsenobetaine (64436-13-1)", value: "Arsenobetaine (64436-13-1)"},
{ group: "Elements", label: "Arsine, dimethyl (593-57-7)", value: "Arsine, dimethyl (593-57-7)"},
{ group: "Elements", label: "Arsine, methyl (593-52-2)", value: "Arsine, methyl (593-52-2)"},
{ group: "Elements", label: "Barium ()", value: "Barium ()"},
{ group: "Elements", label: "Beryllium ()", value: "Beryllium ()"},
{ group: "Elements", label: "Bismuth ()", value: "Bismuth ()"},
{ group: "Elements", label: "Boron ()", value: "Boron ()"},
{ group: "Elements", label: "Bromide ()", value: "Bromide ()" },
{ group: "Elements", label: "Cadmium ()", value: "Cadmium ()" },
{ group: "Elements", label: "Calcium ()", value: "Calcium ()" },
{ group: "Elements", label: "Cesium ()", value: "Cesium ()" },
{ group: "Elements", label: "Cobalt ()", value: "Cobalt ()" },
{ group: "Elements", label: "Copper ()", value: "Copper ()" },
{ group: "Elements", label: "Gallium ()", value: "Gallium ()" },
{ group: "Elements", label: "Germanium ()", value: "Germanium ()" },
{ group: "Elements", label: "Gold ()", value: "Gold ()" },
{ group: "Elements", label: "Chlorine ()", value: "Chlorine ()" },
{ group: "Elements", label: "Chromium ()", value: "Chromium ()" },
{ group: "Elements", label: "Indium ()", value: "Indium ()" },
{ group: "Elements", label: "Iodide ()", value: "Iodide ()" },
{ group: "Elements", label: "Iridium ()", value: "Iridium ()" },
{ group: "Elements", label: "Iron ()", value: "Iron ()" },
{ group: "Elements", label: "Lead ()", value: "Lead ()" },
{ group: "Elements", label: "Lithium ()", value: "Lithium ()" },
{ group: "Elements", label: "Magnesium ()", value: "Magnesium ()" },
{ group: "Elements", label: "Manganese ()", value: "Manganese ()" },
{ group: "Elements", label: "Mercury ()", value: "Mercury ()" },
{ group: "Elements", label: "Mercury, methyl (22967-92-6)", value: "Mercury, methyl (22967-92-6)" },
{ group: "Elements", label: "Methylselenocysteine ()", value: "Methylselenocysteine ()" },
{ group: "Elements", label: "Molybdenum ()", value: "Molybdenum ()" },
{ group: "Elements", label: "Nickel ()", value: "Nickel ()" },
{ group: "Elements", label: "Niobium ()", value: "Niobium ()" },
{ group: "Elements", label: "Osmium ()", value: "Osmium ()" },
{ group: "Elements", label: "Palladium ()", value: "Palladium ()" },
{ group: "Elements", label: "Phosphorus ()", value: "Phosphorus ()" },
{ group: "Elements", label: "Platinum ()", value: "Platinum ()" },
{ group: "Elements", label: "Potassium ()", value: "Potassium ()" },
{ group: "Elements", label: "Rhenium ()", value: "Rhenium ()" },
{ group: "Elements", label: "Rhodium ()", value: "Rhodium ()" },
{ group: "Elements", label: "Rubidium ()", value: "Rubidium ()" },
{ group: "Elements", label: "Ruthenium ()", value: "Ruthenium ()" },
{ group: "Elements", label: "Scandium ()", value: "Scandium ()" },
{ group: "Elements", label: "Selenium ()", value: "Selenium ()" },
{ group: "Elements", label: "Selenocysteine ()", value: "Selenocysteine ()" },
{ group: "Elements", label: "Silicon ()", value: "Silicon ()" },
{ group: "Elements", label: "Silver (7440-22-4)", value: "Silver (7440-22-4)" },
{ group: "Elements", label: "Sodium ()", value: "Sodium ()" },
{ group: "Elements", label: "Strontium ()", value: "Strontium ()" },
{ group: "Elements", label: "Sulfur ()", value: "Sulfur ()" },
{ group: "Elements", label: "Tantalum ()", value: "Tantalum ()" },
{ group: "Elements", label: "Tellurium ()", value: "Tellurium ()" },
{ group: "Elements", label: "Thallium ()", value: "Thallium ()" },
{ group: "Elements", label: "Thorium ()", value: "Thorium ()" },
{ group: "Elements", label: "Tin ()", value: "Tin ()" },
{ group: "Elements", label: "Titanium ()", value: "Titanium ()" },
{ group: "Elements", label: "Tungsten ()", value: "Tungsten ()" },
{ group: "Elements", label: "Uranium ()", value: "Uranium ()" },
{ group: "Elements", label: "Vanadium ()", value: "Vanadium ()" },
{ group: "Elements", label: "Yttrium ()", value: "Yttrium ()" },
{ group: "Elements", label: "Zinc ()", value: "Zinc ()" },
{ group: "Elements", label: "Zirconium ()", value: "Zirconium ()" },


    ]
}
