export const section_info = {
    code: 'info',
    label : 'Please, fill in unique combinations of <strong>chemical parameters</strong> and environmental <strong>matrices</strong>. You can fill in multiple combinations using the button "add next".',
    dataType : 'heading',
}
export const section_matrix = {
    code: 'matrix',
    label : 'Matrix',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        { label: "Outdoor Air", value: "outdoor_air" },
        { label: "Indoor Air", value: "indoor_air" },
        { label: "Indoor Dust", value: "indoor_dust" },
        { label: "Water", value: "water" },
        { label: "Sediment", value: "sediment" },
        { label: "Soil", value: "soil" },
        { label: "Food and feed", value: "food_and_feed" },
        { label: "Material (articles)", value: "material_articles" },
    
    ]
}


export const other_parameter = {
            code: 'otherExtended',
            label : 'Other parameters(s)',
            dataType : 'string',
            multiselect: false,
            isValid: true,
            isDisabled: false,
            hint: 'In case you don\'t find your compound in the offered list, please, fill in your other compounds with CAS number in brackets. Use semicolon (;) as delimiter between names of compounds.',
}
