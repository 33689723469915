export const parameter = {
    code: 'pharmaceuticals',
    label : 'Pharmaceuticals',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        
{ group: "Pharmaceuticals", label: "acetaminophen (103-90-2)", value: "acetaminophen (103-90-2)" },
{ group: "Pharmaceuticals", label: "atenolol (29122-68-7)", value: "atenolol (29122-68-7)" },
{ group: "Pharmaceuticals", label: "azoxystrobine   (131860-33-8)", value: "azoxystrobine   (131860-33-8)" },
{ group: "Pharmaceuticals", label: "butyl methoxydibenzoylmethane (70356-09-1)", value: "butyl methoxydibenzoylmethane (70356-09-1)" },
{ group: "Pharmaceuticals", label: "caffeine (58-08-2)", value: "caffeine (58-08-2)" },
{ group: "Pharmaceuticals", label: "carbamazepine (298-46-4)", value: "carbamazepine (298-46-4)" },
{ group: "Pharmaceuticals", label: "ciprofloxacin (85721-33-1)", value: "ciprofloxacin (85721-33-1)" },
{ group: "Pharmaceuticals", label: "clindamycine (18323-44-9)", value: "clindamycine (18323-44-9)" },
{ group: "Pharmaceuticals", label: "clofibric acid (882-09-7)", value: "clofibric acid (882-09-7)" },
{ group: "Pharmaceuticals", label: "clotrimazole (23593-75-1)", value: "clotrimazole (23593-75-1)" },
{ group: "Pharmaceuticals", label: "desvenlafaxine (93413-62-8)", value: "desvenlafaxine (93413-62-8)" },
{ group: "Pharmaceuticals", label: "diclofenac (15307-86-5)", value: "diclofenac (15307-86-5)" },
{ group: "Pharmaceuticals", label: "diethyltoluamide (134-62-3)", value: "diethyltoluamide (134-62-3)" },
{ group: "Pharmaceuticals", label: "diflufenican (83164-33-4)", value: "diflufenican (83164-33-4)" },
{ group: "Pharmaceuticals", label: "dimoxystrobine (149961-52-4)", value: "dimoxystrobine (149961-52-4)" },
{ group: "Pharmaceuticals", label: "famoxadone (131807-57-3)", value: "famoxadone (131807-57-3)" },
{ group: "Pharmaceuticals", label: "fipronil (120068-37-3)", value: "fipronil (120068-37-3)" },
{ group: "Pharmaceuticals", label: "fluconazole (86386-73-4)", value: "fluconazole (86386-73-4)" },
{ group: "Pharmaceuticals", label: "guanylurea (141-83-3)", value: "guanylurea (141-83-3)" },
{ group: "Pharmaceuticals", label: "hydrochlorothiazide (58-93-5)", value: "hydrochlorothiazide (58-93-5)" },
{ group: "Pharmaceuticals", label: "ibuprofen (15687-27-1)", value: "ibuprofen (15687-27-1)" },
{ group: "Pharmaceuticals", label: "imazalil (35554-44-0)", value: "imazalil (35554-44-0)" },
{ group: "Pharmaceuticals", label: "ipconazole (125225-28-7)", value: "ipconazole (125225-28-7)" },
{ group: "Pharmaceuticals", label: "ketoprofen (22071-15-4)", value: "ketoprofen (22071-15-4)" },
{ group: "Pharmaceuticals", label: "metconazole (125116-23-6)", value: "metconazole (125116-23-6)" },
{ group: "Pharmaceuticals", label: "metformine  (657-24-9)", value: "metformine  (657-24-9)" },
{ group: "Pharmaceuticals", label: "miconazole (22916-47-8)", value: "miconazole (22916-47-8)" },
{ group: "Pharmaceuticals", label: "naproxen (22204-53-1)", value: "naproxen (22204-53-1)" },
{ group: "Pharmaceuticals", label: "octocrylene (6197-30-4)", value: "octocrylene (6197-30-4)" },
{ group: "Pharmaceuticals", label: "ofloxacine (82419-36-1)", value: "ofloxacine (82419-36-1)" },
{ group: "Pharmaceuticals", label: "oxybenzone (131-57-7)", value: "oxybenzone (131-57-7)" },
{ group: "Pharmaceuticals", label: "penconazole (66246-88-6)", value: "penconazole (66246-88-6)" },
{ group: "Pharmaceuticals", label: "prochloraze (67747-09-5)", value: "prochloraze (67747-09-5)" },
{ group: "Pharmaceuticals", label: "sulfamethoxazole (723-46-6)", value: "sulfamethoxazole (723-46-6)" },
{ group: "Pharmaceuticals", label: "tebuconazole (107534-96-3)", value: "tebuconazole (107534-96-3)" },
{ group: "Pharmaceuticals", label: "tetraconazole (112281-77-3)", value: "tetraconazole (112281-77-3)" },
{ group: "Pharmaceuticals", label: "triclocarban (101-20-2)", value: "triclocarban (101-20-2)" },
{ group: "Pharmaceuticals", label: "triclosan (3380-34-5)", value: "triclosan (3380-34-5)" },
{ group: "Pharmaceuticals", label: "trimethoprim (738-70-5)", value: "trimethoprim (738-70-5)" },
{ group: "Pharmaceuticals", label: "venlafaxine  (93413-69-5)", value: "venlafaxine  (93413-69-5)" },


    ]
}
