export const parameter = {
    code: 'pcns',
    label : 'PCNs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "PCNs", label: "PCN 2 (91-58-7)", value: "PCN 2 (91-58-7)" },
{ group: "PCNs", label: "PCN 6 (1825-30-5)", value: "PCN 6 (1825-30-5)" },
{ group: "PCNs", label: "PCN 13 (50402-52-3)", value: "PCN 13 (50402-52-3)" },
{ group: "PCNs", label: "PCN 27 (20020-02-4)", value: "PCN 27 (20020-02-4)" },
{ group: "PCNs", label: "PCN 28 (53555-63-8)", value: "PCN 28 (53555-63-8)" },
{ group: "PCNs", label: "PCN 28+36 (NA)", value: "PCN 28+36 (NA)" },
{ group: "PCNs", label: "PCN 36 (67922-22-9)", value: "PCN 36 (67922-22-9)" },
{ group: "PCNs", label: "PCN 46 (3432-57-3)", value: "PCN 46 (3432-57-3)" },
{ group: "PCNs", label: "PCN 48 (34588-40-4)", value: "PCN 48 (34588-40-4)" },
{ group: "PCNs", label: "PCN 50 (67922-26-3)", value: "PCN 50 (67922-26-3)" },
{ group: "PCNs", label: "PCN 52 (53555-65-0)", value: "PCN 52 (53555-65-0)" },
{ group: "PCNs", label: "PCN 53 (150224-24-1)", value: "PCN 53 (150224-24-1)" },
{ group: "PCNs", label: "PCN 60 (150224-17-2)", value: "PCN 60 (150224-17-2)" },
{ group: "PCNs", label: "PCN 61 (150224-22-9)", value: "PCN 61 (150224-22-9)" },
{ group: "PCNs", label: "PCN 66 (103426-96-6)", value: "PCN 66 (103426-96-6)" },
{ group: "PCNs", label: "PCN 69 (103426-94-4)", value: "PCN 69 (103426-94-4)" },
{ group: "PCNs", label: "PCN 72 (103426-92-2)", value: "PCN 72 (103426-92-2)" },
{ group: "PCNs", label: "PCN 73 (58863-14-2)", value: "PCN 73 (58863-14-2)" },
{ group: "PCNs", label: "PCN 75 (2234-13-1)", value: "PCN 75 (2234-13-1)" },


    ]
}

