export const parameter = {
    code: 'paraffins',
    label : 'Paraffins',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [
        
{ group: "Paraffins", label: "medium-chained chloroalkanes (C14-17) (63449-39-8)", value: "medium-chained chloroalkanes (C14-17) (63449-39-8)" },
{ group: "Paraffins", label: "short-chained chloroalkanes (C10-13) (85535-84-8)", value: "short-chained chloroalkanes (C10-13) (85535-84-8)" },
{ group: "Paraffins", label: "long-chained chloroalkanes (C18-30) (106232-86-4 )", value: "long-chained chloroalkanes (C18-30) (106232-86-4 )" },


    ]
}
