<template>
  <thead>
    <tr :class="[$vuetify.theme.dark ? '' : 'grey lighten-3']">
      <th class="pa-3" v-if="tableSettings.selectable" style="width: 1px;">
        <v-checkbox
            v-model="headerAllComp"
            color="primary"
            :ripple="false"
            :indeterminate="indeterminate"
            hide-details
            class="ma-0 pa-0"
        ></v-checkbox>
      </th>
      <th
          v-for="header in headers"
          :key="header.value"
          :style="{ position: 'relative' , minWidth: header.width || 'auto' }"
          :class="['header__cell pa-3', header.sortable ? 'pr-6 sortable' : '']"
          @click="header.sortable ? $emit('sort', header.value) : false"
      >
        <span
            :class="['header__item caption text-left font-weight-medium']"
            v-html="header.text ? header.text : ($vuetify.lang.t(`$vuetify.tableHeaders.${header.value}`) ? $vuetify.lang.t(`$vuetify.tableHeaders.${header.value}`) : header.value)"
        ></span>
        <v-icon size="20" v-if="tableOptions.sortBy.includes(header.value)" :class="['header__sort-icon', tableOptions.sortDesc[tableOptions.sortBy.indexOf(header.value)] ? 'down' : 'up' ]">
          mdi-chevron-up
        </v-icon>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'TableHeader',
  props: ['headers', 'tableSettings', 'tableOptions', 'headerAll', 'indeterminate'],
  computed: {
    headerAllComp: {
      get () {
        return this.headerAll
      },
      set (newVal) {
        this.$emit('toggle-all', newVal)
      }
    }
  }
}
</script>

<style lang="scss">
  .theme--light{
    thead {
      tr {
        th {
          border-right: 1px solid white;
          border-left: 0;
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  .theme--dark{
    thead {
      tr {
        th {
          border-right: 1px solid var(--v-secondary-base);
          border-left: 0;
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  thead {
    tr {
      vertical-align: bottom;
    }
  }
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }

  .header__cell {
    position: relative;
    &.sortable {
      cursor: pointer;
      padding-right: map-get($grid-gutters, xl);
      &:hover {
        .header__item {
          color: var(--v-secondary-base) !important;
          + i {
            color: var(--v-secondary-base) !important;
          }
        }
      }
    }
    .header__item {
      transition: color 0.2s ease;
      color: var(--v-secondary-lighten2) !important;
      word-break: keep-all;
      + i {
        &.down {
          transform: rotate(180deg);
        }
      }
    }
    .header__sort-icon {
      position: absolute;
      right: map-get($grid-gutters, sm);
      top: calc(100% - 32px);
    }
  }

</style>
