export const parameter = {
    code: 'pcps',
    label : 'OCPs',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Pesticides - basic", label: "DDD-o,p' (53-19-0)", value: "DDD-o,p' (53-19-0)" },
{ group: "Pesticides - basic", label: "DDD-p,p' (72-54-8)", value: "DDD-p,p' (72-54-8)" },
{ group: "Pesticides - basic", label: "DDE-o,p' (3424-82-6)", value: "DDE-o,p' (3424-82-6)" },
{ group: "Pesticides - basic", label: "DDE-p,p' (72-55-9)", value: "DDE-p,p' (72-55-9)" },
{ group: "Pesticides - basic", label: "DDT-o,p' (789-02-6)", value: "DDT-o,p' (789-02-6)" },
{ group: "Pesticides - basic", label: "DDT-p,p' (50-29-3)", value: "DDT-p,p' (50-29-3)" },
{ group: "Pesticides - basic", label: "HCB (118-74-1)", value: "HCB (118-74-1)" },
{ group: "Pesticides - basic", label: "HCH-alpha (319-84-6)", value: "HCH-alpha (319-84-6)" },
{ group: "Pesticides - basic", label: "HCH-beta (319-85-7)", value: "HCH-beta (319-85-7)" },
{ group: "Pesticides - basic", label: "HCH-delta (319-86-8)", value: "HCH-delta (319-86-8)" },
{ group: "Pesticides - basic", label: "HCH-epsilon (6108-10-7)", value: "HCH-epsilon (6108-10-7)" },
{ group: "Pesticides - basic", label: "HCH-gamma (58-89-9)", value: "HCH-gamma (58-89-9)" },
{ group: "Pesticides - basic", label: "PeCB (608-93-5)", value: "PeCB (608-93-5)" },


    ]
}
