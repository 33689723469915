export const parameter = {
    code: 'phthalates',
    label : 'Phthalates',
    dataType : 'enum',
    widgetType: 'autocomplete',
    chips: true,
    multiselect: true,
    options: [

{ group: "Phthalates", label: "benzylbutylphthalate (85-68-7)", value: "benzylbutylphthalate (85-68-7)" },
{ group: "Phthalates", label: "diethyl phthalate (84-66-2)", value: "diethyl phthalate (84-66-2)" },
{ group: "Phthalates", label: "diisodecyl phthalate (26761-40-0)", value: "diisodecyl phthalate (26761-40-0)" },
{ group: "Phthalates", label: "diisononyl phthalate (28553-12-0)", value: "diisononyl phthalate (28553-12-0)" },
{ group: "Phthalates", label: "diisopropylnaphthalene (38640-62-9)", value: "diisopropylnaphthalene (38640-62-9)" },
{ group: "Phthalates", label: "dimetylphthalate (131-11-3)", value: "dimetylphthalate (131-11-3)" },
{ group: "Phthalates", label: "di-n-butylphthalate (84-74-2)", value: "di-n-butylphthalate (84-74-2)" },
{ group: "Phthalates", label: "di-n-octylphthalate (117-84-0)", value: "di-n-octylphthalate (117-84-0)" },

    ]
}
