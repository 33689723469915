<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 87.5 100" height="100%" xml:space="preserve">
    <polyline class="cls-1" fill="#1697f6" points="43.75 0 23.31 0 43.75 48.32"/>
    <polygon class="cls-2" fill="#7bc6ff" points="43.75 62.5 43.75 100 0 14.58 22.92 14.58 43.75 62.5"/>
    <polyline class="cls-3" fill="#1867c0" points="43.75 0 64.19 0 43.75 48.32"/>
    <polygon class="cls-4" fill="#aeddff" points="64.58 14.58 87.5 14.58 43.75 100 43.75 62.5 64.58 14.58"/>
  </svg>
</template>

<script>
export default {
  name: 'VuetifyLogo'
}
</script>
