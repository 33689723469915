<template>
  <v-container :fluid="fluidProp">
    <v-row justify="start">
      <v-col cols="auto" class="display-1 font-weight-bold primary--text" sm="12" md="10" lg="8" xl="6">
        <h3>Surveys of {{ departmentLabel }} - {{ serviceLabel }}</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
            ref="requestsTable"
            v-model="selectedRequests"
            hide-default-header
            :headers="tableHeadersComputed"
            :items="requestsComputed"
            :options.sync="requestsTableOptions"
            :server-items-length="requestsTotal"
            :footer-props="{
                  itemsPerPageOptions: [5, 10, 20, 50, 100, 500]
                }"
            class="elevation-0"
            @update:options="updateRequestsTableOptions"
        >
          <template v-slot:header="{ props, on }">
            <table-header
                :table-settings="requestsTableSettings"
                :table-options="requestsTableOptions"
                :indeterminate="allRequestsIndeterminate"
                :header-all="allRequests"
                :headers="props.headers"
                @sort="on.sort"
                @toggle-all="toggleAllRequests"
            ></table-header>
          </template>
          <!--              , options, sort, isSelected, isExpanded, expand-->
          <template v-slot:body="{ items, headers, isSelected, isExpanded }">

            <!-- loading slot -->
            <tbody v-if="requestsLoading">
              <tr class="text-left">
                <td :colspan="requestsTableSettings.selectable ? headers.length + 1 : headers.length" class="pa-0">
                  <v-progress-linear
                      background-color="none"
                      color="primary"
                      indeterminate
                  ></v-progress-linear>
                  <div class="pa-3 body-2" v-if="!items.length">{{ $vuetify.lang.t('$vuetify.loading') }}</div>
                </td>
              </tr>
            </tbody>

            <!-- item slot -->
            <tbody
                v-for="(item, index) in items"
                :key="index"
            >
            <table-body-row
                :item="item"
                :table-settings="requestsTableSettings"
                :table-options="requestsTableOptions"
                :headers="headers"
                :is-selected="isSelected"
                :is-expanded="isExpanded"
                @select-item="selectRequest(item)"
                @preview-item="gotoRequestDetail(item)"
            ></table-body-row>
            </tbody>

            <!-- no data slot -->
            <tbody v-if="!items.length && !requestsLoading">
              <tr>
                <td class="pa-3" :colspan="requestsTableSettings.selectable ? headers.length + 1 : headers.length">
                  <v-alert
                      tile
                      dense
                      border="left"
                      colored-border
                      color="tertiary"
                      class="ma-0 pa-0"
                  >
                    <v-container fluid>
                      <v-row align="center" justify="start">
                        <v-col class="shrink text-center">
                          <v-icon>mdi-information</v-icon>
                        </v-col>
                        <v-col class="shrink text-center text-no-wrap">
                          <span>{{ $vuetify.lang.t('$vuetify.noDataText') }}</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-alert>
                </td>
              </tr>
            </tbody>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from './../plugins/axiosInstance'
import { departments } from './../components/form/config/departments'
import TableHeader from '@/components/dataTable/TableHeader'
import TableBodyRow from '@/components/dataTable/TableBodyRow'

export default {
  name: 'SurveysView',
  props: ['fluidProp', 'department', 'service'],
  components: {
    TableHeader,
    TableBodyRow
  },
  data () {
    return {
      headers: [
        // text: string, (kdyz je text, pouzije se, neni-li pouzije se bud preklad value nebo value
        // value: string,
        // align?: 'start' | 'center' | 'end',
        // sortable?: boolean,
        // filterable?: boolean,
        // groupable?: boolean,
        // divider?: boolean,
        // class?: string | string[],
        // cellClass?: string | string[],
        // width?: string | number,
        // filter?: (value: any, search: string, item: any) => boolean,
        // sort?: (a: any, b: any) => number
        // ---- Additional ----
        // ordering?: number
        // type?: string ('string' | 'datetime' | 'enum')
        // enumApi?: string (api na polozky ciselniku)
        // link?: boolean (policko jako link na detail)
        // active?: boolean (defaultne zobrazeny sloupec)

        // fullForm ???
        {
          value: 'id',
          align: 'start',
          sortable: true,
          filterable: false,
          ordering: 1,
          type: 'number',
          active: true,
          // link: '/survey-detail/' + this.department + '/' + this.service,
        },
        {
          value: 'emailAddress',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 4,
          type: 'string',
          active: false,
        },
        {
          value: 'fullName',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 5,
          type: 'string',
          active: true,
        },
        {
          value: 'surveyFormState',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 6,
          type: 'enum',
          enumApi: 'request_states',
          active: false,
        },
        {
          value: 'surveyFormType', // this.department
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 7,
          type: 'string',
          active: false,
        },

        {
          value: 'monitoringNetworkTitle',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 10,
          type: 'string',
          active: true,
        },
        {
          value: 'monitoringNetworkAcronym',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 11,
          type: 'string',
          active: true,
        },
        {
          value: 'monitoringNetworkDataOwner',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 12,
          type: 'string',
          active: true,
        },
        {
          value: 'monitoringNetworkDataSourceType',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 13,
          type: 'string',
          active: true,
        },
        {
          value: 'monitoringNetworkWebPage',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 14,
          type: 'string',
          active: true,
        },

        {
          // text: 'createdAt',
          value: 'createdAt',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 18,
          type: 'datetime',
          active: false,
        },
        {
          // text: 'updatedAt',
          value: 'updatedAt',
          align: 'start',
          sortable: false,
          filterable: false,
          ordering: 19,
          type: 'datetime',
          active: false,
        },
      ],
      availableFilters: [],
      visibleColumns: [],
      requestsLoading: false,
      requests: [],
      selectedRequests: [],
      requestsTotal: 0,
      requestsTableOptions: {
        page: null,
        itemsPerPage: null,
        sortBy: [],
        sortDesc: []
      },
      requestsTableSettings: {
        selectable: false,
        // <-- TODO
        expandable: false,
        draggable: false,
        // TODO -->
        actions: [
          {
            name: 'detail',
            action: 'preview-item',
            icon: 'mdi-magnify',
            color: 'success',
            isDisable: false,
            isVisible: true
          }
          // {
          //   name: 'edit',
          //   action: 'edit-item',
          //   icon: 'mdi-pencil',
          //   color: 'secondary',
          //   isDisable: true,
          //   isVisible: false
          // },
          // {
          //   name: 'remove',
          //   action: 'remove-item',
          //   icon: 'mdi-delete',
          //   color: 'error',
          //   isDisable: true,
          //   isVisible: false
          // }
        ],
        actionsAs: process.env.NODE_ENV === 'production' ? ['tableCell', 'contextMenu'] : ['tableCell'] // 'tableCell' | 'contextMenu'
      },
      allRequests: false,
      allRequestsIndeterminate: false,
    }
  },
  computed: {
    tableHeadersComputed () {
      let computedHeaders = Object.assign([], this.headers)
      return computedHeaders.filter(h => h.active).sort((a, b) => {
        return a.ordering - b.ordering
      })
    },
    requestsComputed () {
      return this.requests
    },
    departmentLabel () {
      if (departments.find(dep => dep.path === this.department)) return departments.find(dep => dep.path === this.department).label
      return this.$route.params.department
    },
    serviceLabel () {
      if (departments.map(dep => dep.services).flat().find(ser => ser.path === this.service)) return departments.map(dep => dep.services).flat().find(ser => ser.path === this.service).label
      return this.$route.params.service
    }

    // formConfig () {
    //   if (this.department && this.service) return forms[`${this.department}_${this.service}_Config`];
    //   return [];
    // },
  },
  // mounted () {
  //   console.log(departments.map(dep => dep.services).flat())
  // },
  beforeMount () {
    // this.availableFilters = this.headers.filter(header => header.filterable)
    // this.visibleColumns = this.$route.query['requestsViewVisibleColumns[]'] || this.headers.filter(header => header.active).map(header => header.value)
    // this.visibleColumns = this.$cookies.get('requestsViewVisibleColumns') ? this.$cookies.get('requestsViewVisibleColumns')['requestsViewVisibleColumns[]'] : this.headers.filter(header => header.active).map(header => header.value)
    // if (this.$cookies.get('requestsViewVisibleColumns')) {
    //   this.visibleColumns = this.$cookies.get('requestsViewVisibleColumns')['requestsViewVisibleColumns[]']
    // } else if (this.$route.query['requestsViewVisibleColumns[]']) {
    //   this.visibleColumns = this.$route.query['requestsViewVisibleColumns[]']
    // } else {
    //   this.visibleColumns = this.headers.filter(header => header.active).map(header => header.value)
    // }
    // if (!Array.isArray(this.visibleColumns)) this.visibleColumns = [this.visibleColumns]
    this.requestsTableOptions.page = Number(this.$route.query.page) || 1
    this.requestsTableOptions.itemsPerPage = Number(this.$route.query.itemsPerPage) || Number(process.env.VUE_APP_ITEMS_PER_PAGE)
    this.requestsTableOptions.sortBy = Object.keys(this.$route.query).find(key => key.includes('requestFormsOrder')) ? [Object.keys(this.$route.query).find(key => key.includes('requestFormsOrder')).match(/\[(.*?)\]/)[1]] : this.requestsTableOptions.sortBy
    this.requestsTableOptions.sortDesc = Object.keys(this.$route.query).find(key => key.includes('requestFormsOrder')) ? [this.$route.query[Object.keys(this.$route.query).find(key => key.includes('requestFormsOrder'))] === 'desc' ? true : false] : this.requestsTableOptions.sortDesc
  },
  methods: {
    updateRequestsTableOptions () {
      let queries = this.$route.query
      delete queries[Object.keys(queries).find(key => key.includes('requestFormsOrder'))]
      delete queries.page
      delete queries.itemsPerPage
      // console.log(this.$route.query)
      const params = {
        page: this.requestsTableOptions.page,
        itemsPerPage: this.requestsTableOptions.itemsPerPage,
        ...queries
      }
      if (this.requestsTableOptions.itemsPerPage < 0) delete params.itemsPerPage

      if (this.requestsTableOptions.sortBy.length) {
        params[`requestFormsOrder[${this.requestsTableOptions.sortBy[0]}]`] = this.requestsTableOptions.sortDesc[0] ? 'desc' : 'asc'
      }

      // visibleColumns
      // params['requestsViewVisibleColumns[]'] = this.tableHeadersComputed.map(header => header.value)

      // specials for survey
      params['surveyFormType'] = this.department.toUpperCase()

      this.$router.replace({ query: params }, () => {})
      // this.$cookies.remove('requestsViewVisibleColumns')
      // this.$cookies.set('requestsViewVisibleColumns', params)
      this.fetchRequests()
    },
    async fetchRequests () {
      this.requestsLoading = true
      try {
        const result = await axios.get('/api/request_forms', { params: this.$route.query })
        this.requests = result.data['hydra:member']
        this.requestsTotal = result.data['hydra:totalItems']
        // console.log(result)
      }catch (error) {
        console.log(error)
        this.$emit('show-snack', { text: '<strong>' + error.response.status + ': ' + error.response.statusText + ' - </strong>' + error.response.data['hydra:title'] + ', ' + error.response.data['hydra:description'], color: 'error', ico: 'mdi-thumb-down' })
      }
      this.requestsLoading = false
    },
    gotoRequestDetail (request) {
      // console.log(request)
      this.$router.push({ name: 'surveys-detail-view', params: {
          department: this.department,
          service: this.service,
          id: request.id,
          fullForm: request.fullForm
        } }, () => {})
    },
    selectRequest (request) {
      // console.log('selectFs', fs)
      // console.log(this.selectedFs)
      const index = this.selectedRequests.map(it => it.id).indexOf(request.id)
      if (index > -1) {
        this.selectedRequests.splice(index, 1)
      } else {
        this.selectedRequests.push(request)
      }
      this.setIntermediate()
      // this.$emit('set-selected-items', { selectedItems: this.selectedRequests })
    },
    toggleAllRequests (toggleAll) {
      if (!toggleAll) {
        // console.log('vsecko pryc')
        this.selectedRequests = []
      } else {
        // console.log('vsecko pridat')
        this.selectedRequests = [...this.requestsComputed]
      }
      this.setIntermediate()
    },
    setIntermediate () {
      if (this.selectedRequests.length !== 0) {
        if (this.selectedRequests.length === this.requestsTotal) {
          this.allRequests = true
          this.allRequestsIndeterminate = false
        } else {
          this.allRequestsIndeterminate = true
          this.allRequests = false
        }
      } else {
        this.allRequestsIndeterminate = false
        this.allRequests = false
      }
    },
  }
};
</script>

<style scoped></style>
